<template>
  <h3>
    Sent Sponsorships
    <el-button
      :loading="isLoading"
      circle
      icon="el-icon-refresh-right"
      size="mini"
      @click="refreshSentVotes()"
    ></el-button>
  </h3>

  <el-table
    v-loading="isLoading"
    :data="sentSponsorships"
    height="400"
    style="width: 100%"
  >
    <!--    <el-table-column type="expand">-->
    <!--      <template #default="props">-->
    <!--        <p>Existing bids on blockstory #{{ props.row.StoryId }}</p>-->
    <!--        <sent-bids-list :StoryId="props.row.StoryId"></sent-bids-list>-->
    <!--      </template>-->
    <!--    </el-table-column>-->
    <el-table-column label="Sponsor" min-width="150" prop="id" sortable />
    <el-table-column
      label="Proposal"
      min-width="150"
      prop="proposalId"
      sortable
    />
    <el-table-column
      fixed="right"
      label="Bid (AVAX)"
      prop="StringVoteAmount"
      sortable
      width="150"
    />
    <el-table-column fixed="right" label="Operations" width="120">
      <template #default="scope">
        <el-button size="small" type="text" @click="cancelVote(scope.row.id)"
          >Cancel
        </el-button>
        <el-button
          size="small"
          type="text"
          @click="claimVote(scope.row.farmerId)"
          >Claim
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "SentSponsorships",
  components: {},
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    sentSponsorships() {
      if (!this.$store.state.initialized) {
        this.loading();
        return [];
      }

      this.ready();
      return this.$store.state.voteService.SyncGetCurrentAddressSentVotes();
    },
  },
  methods: {
    ready() {
      this.isLoading = false;
    },
    loading() {
      this.isLoading = true;
    },
    async cancelVote(voteId) {
      this.loading();
      await this.$store.state.contractService.CancelVote(voteId);
      this.ready();
    },

    async claimVote(farmerId) {
      this.loading();
      await this.$store.state.contractService.ClaimFarm(farmerId);
      this.ready();
    },
    async refreshSentVotes() {
      this.loading();
      await this.$store.state.voteService.GetCurrentAddressSentVotes();
      this.ready();
    },
    // async cancelBid(bidId) {
    //   this.isLoading = true;
    //   await this.$store.dispatch("cancelBid", { bidId });
    //   this.isLoading = false;
    //   await this.refreshSentTokenBids();
    // },
  },
};
</script>

<style scoped></style>
