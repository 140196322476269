class TxOperation {
  constructor() {
    this.tx = undefined;
    this.err = undefined;
  }

  AddTx(tx) {
    this.tx = tx;
  }

  AddError(err) {
    this.err = err;
  }
}

export default TxOperation;
