<template>
  <el-dialog
    :model-value="IsOwnedStoriesBidsDialogVisible"
    title="Bids on Story "
    width="30%"
    @update:modelValue="
      this.$emit('update:IsOwnedStoriesBidsDialogVisible', $event)
    "
  >
    <el-table :data="receivedTokenBids" height="300" style="width: 100%">
      <el-table-column label="Address" prop="TruncatedOwner" sortable />
      <el-table-column label="Bid" prop="StringBidAmount" sortable />
      <el-table-column label="Operations">
        <template #default="scope">
          <el-button
            size="small"
            type="text"
            @click="this.$emit('acceptBidAction', scope.row.id)"
          >
            Accept Bid
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <template #footer>
      <span class="dialog-footer">
        <el-button
          @click="this.$emit('update:IsOwnedStoriesBidsDialogVisible', false)"
          >Cancel</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "OwnedStoriesBidsDialog",
  props: ["IsOwnedStoriesBidsDialogVisible", "OwnedStoryId"],
  emits: ["acceptBidAction", "update:IsOwnedStoriesBidsDialogVisible"],
  computed: {
    receivedTokenBids() {
      if (!this.$store.state.initialized) {
        return [];
      }

      const arr = [];
      const ownedStories =
        this.$store.state.walletManager.GetReceivedBidsForToken(
          this.OwnedStoryId
        );
      for (const ownedStoryId in ownedStories) {
        arr.push(ownedStories[ownedStoryId]);
      }

      return arr;
    },
  },
};
</script>

<style scoped></style>
