class ContractRepo {
  constructor() {
    this.availableMints = 0;
    this.mintCost = 0;
  }

  get AvailableMints() {
    return this.availableMints;
  }

  get AvailableMintsString() {
    return this.availableMints.toString();
  }

  get MintCost() {
    return this.mintCost;
  }
}

export default ContractRepo;
