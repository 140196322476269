<template>
  <el-row class="home-banner">
    <el-col :span="2"></el-col>
    <el-col :span="20">
      <p class="banner-title color-blue">
        <span class="color-green">our</span> roadmap
      </p>
      <el-row>
        <el-col :span="6" :xs="24">
          <el-row>
            <el-col :span="0" :xs="24">&nbsp;</el-col>
          </el-row>
          <el-row>
            <el-col :span="4" :xs="2" />
            <el-col :span="16" :xs="20">
              <p class="sublist-header banner-green">Quarter 4 2021</p>
              <ul class="sublist">
                <li>Internal Contract development</li>
                <li>blockstory v1 internal release</li>
                <li>Payment tokenomics</li>
              </ul>
            </el-col>
            <el-col :span="3" :xs="2">
              <el-image :src="divImage01" class="sublist-img" />
            </el-col>
            <el-col :span="1" :xs="0" />
          </el-row>
        </el-col>
        <el-col :span="6" :xs="24">
          <el-row>
            <el-col :span="4" :xs="2" />
            <el-col :span="16" :xs="20">
              <p class="sublist-header banner-blue">Quarter 1 2022</p>
              <ul class="sublist">
                <li>blockstory public release</li>
                <li>10,000 NFT Story Mint</li>
                <li>Public Contract release</li>
              </ul>
            </el-col>
            <el-col :span="3" :xs="2">
              <el-image :src="divImage02" class="sublist-img" />
            </el-col>
            <el-col :span="1" :xs="0" />
          </el-row>
        </el-col>
        <el-col :span="6" :xs="24">
          <el-row>
            <el-col :span="4" :xs="2" />
            <el-col :span="16" :xs="20">
              <p class="sublist-header banner-blue-2">Quarter 2 2022</p>
              <ul class="sublist">
                <li>Notifications</li>
                <li>Automatic Buy/Sell/Accept Proposal</li>
                <li>Search and Stats features</li>
                <li>$STORY tokenomics release</li>
              </ul>
            </el-col>
            <el-col :span="3" :xs="2">
              <el-image :src="divImage03" class="sublist-img" />
            </el-col>
            <el-col :span="1" :xs="0" />
          </el-row>
        </el-col>
        <el-col :span="6" :xs="24">
          <el-row>
            <el-col :span="4" :xs="2" />
            <el-col :span="16" :xs="20">
              <p class="sublist-header banner-blue-3">Quarter 3 2022</p>
              <ul class="sublist">
                <li>blockstory owner enhancements</li>
                <li>$STORY DEX listing</li>
                <li>blockstory v2 release</li>
              </ul>
            </el-col>
            <el-col :span="3" :xs="2">
              <el-image :src="divImage04" class="sublist-img" />
            </el-col>
            <el-col :span="1" :xs="0" />
          </el-row>
        </el-col>
      </el-row>
    </el-col>

    <el-col :span="2"></el-col>
  </el-row>
</template>

<script>
export default {
  name: "RoadmapBanner",
  data() {
    return {
      divImage01: require("@/assets/imgs/elements/roadmap/div-01.png"),
      divImage02: require("@/assets/imgs/elements/roadmap/div-02.png"),
      divImage03: require("@/assets/imgs/elements/roadmap/div-03.png"),
      divImage04: require("@/assets/imgs/elements/roadmap/div-04.png"),
    };
  },
};
</script>

<style scoped>
.sublist {
  padding: 0px;
  text-align: left;
  font-size: 0.9rem;
}

.sublist-header {
  margin-top: 17vh;
  font-size: 1.8rem;
  text-align: right;
  font-weight: bold;
  line-height: 1;
}

.sublist-img {
  width: 6.5vh;
}

@media only screen and (max-width: 1000px) {
  .sublist {
    padding: 0px;
    list-style: inside;
    text-align: left;
    font-size: 0.9rem;
  }

  .sublist-header {
    margin-top: 0vh;
    font-size: 1.8rem;
    text-align: right;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 0vh;
  }

  .sublist-img {
    width: 100%;
  }
}
</style>
