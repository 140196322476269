<template>
  <div class="grid-content bg-purple">
    <div class="block">
      <el-timeline>
        <el-timeline-item
          v-for="story in stories"
          :key="story.Id"
          :timestamp="story.Title"
          placement="top"
        >
          <el-card>
            <story
              v-if="story.Type === 'Story'"
              :OperationButtonVisible="OperationButtonVisible"
              :blockStoryId="story.Id"
              :updateScroll="updateScroll"
            ></story>
            <proposal
              v-if="story.Type === 'Proposal'"
              :OperationButtonVisible="OperationButtonVisible"
              :blockStoryId="story.Id"
              :updateScroll="updateScroll"
            ></proposal>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>

<script>
import Story from "@/components/Story";
import Proposal from "@/components/Proposal";

export default {
  name: "Reader",
  components: { Proposal, Story },
  props: ["StoryId", "OperationButtonVisible"],
  computed: {
    stories() {
      if (!this.$store.state.initialized) {
        return [];
      }
      return this.$store.state.blockStoryManager.GetLinkedBlocks();
    },
  },
  methods: {
    updateScroll() {
      this.$nextTick(() => {
        document
          .querySelector(".el-timeline-item:last-child")
          .scrollIntoView({ behavior: "smooth" });
      });
    },
  },
};
</script>

<style scoped></style>
