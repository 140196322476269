class ActivePollers {
  this;

  constructor(store) {
    this.store = store;
    this.state = store.state;
  }

  Start() {
    if (this.state.initialized) {
      return;
    }

    this.pollWeb3Change();
    this.pollExistingStories();
    this.pollMostExpensiveStories();
  }

  poller(pollingFunc, repeatLoopTime) {
    setInterval(pollingFunc, repeatLoopTime);
  }

  pollWeb3Change() {
    return this.poller(async () => {
      const signer = this.state.walletState.provider.getSigner();
      let currentAddress = await signer.getAddress();
      if (this.state.walletState.CurrentAddress !== currentAddress) {
        this.state.walletState.CurrentAddress = currentAddress;
        console.log("Address changed to: %s", currentAddress);
        this.state.initialized = false;
        this.store.dispatch("initializeEthers");
      }
    }, 1000);
  }

  pollExistingStories() {
    return this.poller(async () => {
      await this.state.explorerService.UpdateLatestStories();
    }, this.regressivePolling());
  }

  pollMostExpensiveStories() {
    return this.poller(async () => {
      await this.state.explorerService.UpdateMostExpensiveStories();
    }, this.regressivePolling());
  }

  regressivePolling() {
    return 5000;
  }
}

export default ActivePollers;
