<template>
  <div v-for="i in this.SizeMultiplier" :key="i">
    <p>&nbsp;</p>
  </div>
</template>

<script>
export default {
  name: "BlankSpaceDivider",
  props: {
    SizeMultiplier: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    iter() {
      console.log(this.SizeMultiplier);
      if (this.SizeMultiplier === undefined) {
        console.log("nop");
        return 1;
      }
      console.log("yep");
      return this.SizeMultiplier;
    },
  },
};
</script>

<style scoped>
p {
  padding-top: 5vh;
  padding-bottom: 5vh;
}
</style>
