<template>
  <div class="grid-content bg-purple">
    <div class="block">
      <el-timeline>
        <el-timeline-item
          v-for="story in stories"
          :key="story.Id"
          :timestamp="story.Title"
          placement="top"
        >
          <el-card>
            <div
              style="
                text-align: justify;
                text-justify: inter-word;
                font-family: charter, Georgia, Cambria, 'Times New Roman', Times,
                  serif;
                font-size: 21px;
              "
            >
              <p></p>
              <MarkdownViewer :source="story.Text" />
              <p></p>
              <h6 v-for="choice in choices" :key="choice.Id">
                <el-button
                  plain
                  type="primary"
                  v-on:click="updateBlockStory(choice.Id)"
                  >{{ choice.Title }}
                </el-button>
              </h6>

              <el-row>
                <el-col :span="20"></el-col>
                <el-col :span="4">
                  <p></p>
                </el-col>
              </el-row>
            </div>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>

<script>
import MarkdownViewer from "@/components/reader/MarkdownViewer";

export default {
  name: "ProposalStoryReader",
  components: { MarkdownViewer },
  props: ["ProposalId"],
  computed: {
    stories() {
      if (!this.$store.state.initialized) {
        return [];
      }
      return this.$store.state.proposalBlockStoryManager.GetLinkedProposalBlocks();
    },
    choices() {
      if (!this.$store.state.initialized) {
        return null;
      }
      let story = this.$store.state.proposalBlockStoryManager.GetStory(
        this.ProposalId
      );
      if (story === undefined) {
        story = this.$store.state.proposalBlockStoryManager.GetProposal(
          this.ProposalId
        );
      }
      return story.Linkage;
    },
    activeText() {
      if (!this.$store.state.initialized) {
        return null;
      }
      let story = this.$store.state.proposalBlockStoryManager.GetStory(
        this.ProposalId
      );
      if (story === undefined) {
        story = this.$store.state.proposalBlockStoryManager.GetProposal(
          this.ProposalId
        );
      }
      return story.Text;
    },
  },
  methods: {
    updateScroll() {
      this.$nextTick(() => {
        document
          .querySelector(".el-timeline-item:last-child")
          .scrollIntoView({ behavior: "smooth" });
      });
    },
    async updateBlockStory(choiceId) {
      await this.$store.dispatch("updateBlockStory", {
        storyId: this.blockStoryId,
        choiceId,
      });
      this.updateScroll();
    },

    toggleProposer() {
      this.proposeWindowVisible = !this.proposeWindowVisible;
    },
    toggleBuy() {
      this.buyWindowVisible = !this.buyWindowVisible;
    },
    enter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = "absolute";
      element.style.visibility = "hidden";
      element.style.height = "auto";

      const height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      // Trigger the animation.
      // We use `requestAnimationFrame` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
    leave(element) {
      const height = getComputedStyle(element).height;

      element.style.height = height;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    },
  },
};
</script>

<style scoped></style>
