<template>
  <h3>
    Received Proposals
    <el-button
      :loading="isLoading"
      circle
      icon="el-icon-refresh-right"
      size="mini"
      @click="refreshProposals()"
    ></el-button>
  </h3>

  <el-table
    v-loading="isLoading"
    :data="receivedProposals"
    height="400"
    style="width: 100%"
  >
    <el-table-column label="" sortable type="expand">
      <template #default="scope">
        <sponsor-breakdown-list :ProposalId="scope.row.id" />
      </template>
    </el-table-column>
    <el-table-column
      label="Parent Story"
      min-width="150"
      prop="StringParentStoryTitle"
      sortable
    >
      <template #default="scope">
        <router-link
          :to="{ name: 'BlockStory', params: { story: scope.row.id } }"
        >
          <el-button size="small" type="text"
            >{{ scope.row.StringParentStoryTitle }}
          </el-button>
        </router-link>
      </template>
    </el-table-column>
    <el-table-column label="Proposal" min-width="150" sortable>
      <template #default="scope">
        <el-button
          size="small"
          type="text"
          @click="
            showProposalReaderDialog(scope.row.ParentStoryId, scope.row.id)
          "
          >{{ scope.row.StringProposalTitle }}
        </el-button>
      </template>
    </el-table-column>

    <el-table-column
      fixed="right"
      label="Bid (AVAX)"
      prop="StringBidAmount"
      sortable
      width="150"
    >
    </el-table-column>
    <el-table-column fixed="right" label="Operations" width="120">
      <template #default="scope">
        <el-button
          size="small"
          type="text"
          @click="acceptProposal(scope.row.ParentStoryId, scope.row.Id)"
          >Accept
        </el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-dialog v-model="dialogVisible" width="70%">
    <template #title> Preview Proposal #{{ displayPropId }}</template>
    <proposal-story-reader :ProposalId="displayPropId"></proposal-story-reader>
  </el-dialog>
</template>

<script>
import ProposalStoryReader from "@/components/ProposalStoryReader";
import SponsorBreakdownList from "@/components/wallet/sublists/SponsorBreakdownList";

export default {
  name: "ReceivedProposals",
  components: { SponsorBreakdownList, ProposalStoryReader },
  data() {
    return {
      isLoading: false,
      dialogVisible: false,
      displayPropId: undefined,
    };
  },
  computed: {
    receivedProposals() {
      if (!this.$store.state.initialized) {
        this.loadingReceivedProposals();
        return [];
      }

      if (this.$store.state.walletManager.IsFetchingReceivedProposals) {
        this.loadingReceivedProposals();
        return [];
      }

      const arr = [];

      const proposals = this.$store.state.walletManager.GetReceivedProposals();
      for (const prop in proposals) {
        arr.push(proposals[prop]);
      }

      this.readyReceivedProposals();
      return arr;
    },
  },
  methods: {
    readyReceivedProposals() {
      this.isLoading = false;
    },
    loadingReceivedProposals() {
      this.isLoading = true;
    },

    async showProposalReaderDialog(parentId, propId) {
      this.$store.state.proposalBlockStoryManager.Reset();
      await this.$store.state.proposalBlockStoryManager.SetProposalAsActiveBlock(
        parentId,
        propId
      );
      this.displayPropId = propId;
      this.dialogVisible = true;
    },

    async refreshProposals() {
      this.isLoading = true;
      await this.$store.dispatch("reloadReceivedProposals");
      this.isLoading = false;
    },
    async acceptProposal(storyId, proposalId) {
      await this.$store.dispatch("acceptProposal", { storyId, proposalId });
      await this.refreshProposals();
    },
  },
};
</script>

<style scoped></style>
