<template>
  <el-row class="home-banner white-text regular-text" style="padding-top: 5vh">
    <el-col :span="2"></el-col>
    <el-col :span="20">
      <el-row>
        <el-col :span="11" :xs="24">
          <el-image
            :src="payoutImage"
            class="banner-image"
            fit="contain"
          ></el-image>
        </el-col>
        <el-col :span="1"></el-col>
        <el-col :span="12" :xs="24" style="text-align: left">
          <p class="banner-title">
            dividends <span class="banner-green">and payouts</span>
          </p>
          <p>
            Accepting proposals and selling tokens issue earnings to holders.
          </p>
          <el-divider content-position="left"
            >Profits are payed 3 levels up
          </el-divider>
          <p>&bull; 75% of the amount paid to the owner</p>
          <p>&bull; 15% of the amount paid to the parent</p>
          <p>&bull; 10% of the amount paid to the grand-parent</p>
          <el-divider content-position="left"
            >Each level shares with sponsors
          </el-divider>
          <p>
            &bull; 30% of the profit is for the original-proposer, now owner
          </p>
          <p>
            &bull; 70% of the profit is divided between the proposer and
            sponsors (if any)
          </p>
          <el-divider content-position="left">All operations</el-divider>
          <p>&bull; 0.1% of the amount reverts to the contract</p>
        </el-col>
      </el-row>
    </el-col>
    <el-col :span="2" :xs="0"></el-col>
  </el-row>
</template>

<script>
export default {
  name: "DividendsBanner",
  data() {
    return {
      payoutImage: require("@/assets/imgs/elements/levels-banner.png"),
    };
  },
};
</script>

<style scoped></style>
