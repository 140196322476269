<template>
  <el-card shadow="never">
    <el-table
      v-loading="isLoading"
      :data="stories"
      height="250"
      style="width: 100%"
    >
      <el-table-column label="View" width="100">
        <template #default="scope">
          <router-link
            :to="{ name: 'BlockStory', params: { story: scope.row.id } }"
          >
            <el-button size="small">View</el-button>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column label="Title" prop="StringTitle" />
      <!--      <el-table-column label="Tx" prop="TxHash" />-->
      <el-table-column
        fixed="right"
        label="Block#"
        prop="StringCreatedAt"
        sortable
        width="100"
      />
      <el-table-column
        fixed="right"
        label="Price"
        prop="StringAmountFormatted"
        sortable
        width="100"
      />
    </el-table>
  </el-card>
</template>

<script>
export default {
  name: "LatestStoriesList",
  computed: {
    isLoading() {
      if (!this.$store.state.initialized) {
        return true;
      }

      return this.$store.state.explorerService.Loading;
    },
    stories() {
      if (!this.$store.state.initialized) {
        return [];
      }

      return this.$store.state.storyListManager.GetStories();
    },
  },
};
</script>

<style scoped></style>
