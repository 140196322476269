import { ethers } from "ethers";
import truncateAddress from "@/lib/truncateAddress";

class Bid {
  constructor(id, bid, story) {
    this.id = id;
    this.owner = bid.owner;
    this.bidAmount = bid.bidAmount;
    this.story = story;
  }

  get Id() {
    return this.id;
  }

  get Owner() {
    return this.owner;
  }

  get StoryId() {
    return this.story.Id;
  }

  get BidAmount() {
    return this.bidAmount;
  }

  get StringBidAmount() {
    return ethers.utils.formatEther(this.bidAmount);
  }

  get TruncatedOwner() {
    return truncateAddress(this.owner);
  }

  get ParentStoryTitle() {
    if (this.story.Title === "") {
      return "Not Finalized";
    }

    if (this.story.Title <= 80) {
      return this.story.Title;
    }

    return this.story.Title.slice(0, 74) + "...";
  }
}

export default Bid;
