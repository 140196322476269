import AsyncLock from "@/lib/AsyncLock";
import Transaction from "@/lib/models/transaction";

class TransactionManager {
  constructor() {
    this.transactions = [];
    this.queuedTxs = 0;
    this.lock = new AsyncLock();
    this.sucessfullTxs = {};
  }

  AddFailedTransaction(tx, actionText) {
    const newTx = new Transaction(tx, actionText, "failed");
    this.transactions.push(newTx);
  }

  AddTransaction(tx, actionText) {
    const newTx = new Transaction(tx, actionText, "pending");
    this.addTxToQueue(newTx);
    return newTx.hashId;
  }

  WaitFinished(txHash) {
    const closure = this;
    return new Promise((resolve) => {
      function checkFinished(txHash) {
        if (closure.sucessfullTxs[txHash] !== undefined) {
          resolve("yep");
          return;
        }
        setTimeout(() => {
          checkFinished(txHash);
        }, 300);
      }

      checkFinished(txHash);
    });
  }

  IsWaitingTransactions() {
    return this.queuedTxs > 0;
  }

  GetTransactions() {
    return this.transactions;
  }

  async addTxToQueue(tx) {
    await this.lock.promise;
    this.lock.enable();
    const txPos = this.transactions.length;
    this.transactions.push(tx);
    this.lock.disable();
    this.waitTx(txPos);
  }

  async waitTx(txPos) {
    await this.lock.promise;
    this.lock.enable();
    this.queuedTxs++;
    const tx = this.transactions[txPos];
    this.lock.disable();

    await tx.txData.wait();
    await this.lock.promise;
    this.lock.enable();
    // todo check if approved
    this.transactions[txPos].status = "approved";
    this.sucessfullTxs[tx.txData.hash] = true;
    this.queuedTxs--;
    console.log("Finished waiting on Tx: %s", tx.txData.hash);
    this.lock.disable();
  }
}

export default TransactionManager;
