class ProposalService {
  constructor(state) {
    this.state = state;
  }

  ResetProposalsForStory(storyId) {
    this.state.proposalRepo.ResetProposalsForStory(storyId);
  }

  async GetProposal(propId) {
    return await this.state.proposalRepo.GetProposal(propId);
  }

  SyncGetProposal(propId) {
    return this.state.proposalRepo.SyncGetProposal(propId);
  }

  async GetProposalsForStory(storyId) {
    const proposals = [];
    const gProps = await this.state.graphService.GetStoryProposals(storyId);
    for (const gProp of gProps) {
      proposals.push(await this.GetProposal(gProp.propId));
    }
    return proposals;
  }

  SyncGetProposalsForStory(storyId) {
    const proposals = [];
    const gProps = this.state.graphService.SyncGetStoryProposals(storyId);
    for (const gProp of gProps) {
      proposals.push(this.SyncGetProposal(gProp.propId));
    }
    return proposals;
  }

  SyncGetProposalVotes(proposalId) {
    const breakdown = [];
    const gVotes = this.state.graphService.SyncGetProposalVotes(proposalId);
    for (const gVote of gVotes) {
      let vote = this.state.voteService.SyncGetVote(gVote.voteId);
      vote.SetVoteAmount(gVote.amount);
      breakdown.push(vote);
    }
    return breakdown;
  }

  async GetProposalVotes(proposalId) {
    const breakdown = [];
    const gVotes = await this.state.graphService.GetProposalVotes(proposalId);
    for (const gVote of gVotes) {
      let vote = await this.state.voteService.GetVote(gVote.voteId);
      vote.VoteAmount = gVote.amount;
      breakdown.push(vote);
    }

    return breakdown;
  }

  async ReloadProposals(storyId) {
    this.ResetProposalsForStory(storyId);
    await this.GetProposalsForStory(storyId);
  }
}

export default ProposalService;
