class BidManager {
  constructor(state) {
    this.state = state;
    this.bids = {};
  }

  async ReloadBids(storyId) {
    const storyReceivedBids = await this.state.graphService.GetStoryBids(
      storyId
    );

    for (const bidEvent of storyReceivedBids) {
      let bid = await this.state.bidService.GetBid(bidEvent.bidId);
      this.AddBid(bid);
    }
  }

  AddBid(bid) {
    this.bids[bid.Id] = bid;
  }

  GetBids(storyId) {
    return Object.values(this.bids).filter((v) => v.StoryId === storyId);
  }
}

export default BidManager;
