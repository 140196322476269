<template>
  <div class="WriterDialog">
    <el-dialog
      :before-close="handleClose"
      :model-value="IsDialogVisible"
      :title="DialogTitle"
      width="70%"
      @update:modelValue="this.$emit('update:IsDialogVisible', $event)"
    >
      <writer-base
        v-model:InputText="dialogText"
        v-model:InputTitle="dialogTitle"
        :IsWriterDisabled="IsWriterDisabled"
      ></writer-base>
      <el-button
        :disabled="IsWriterDisabled"
        plain
        type="warning"
        v-on:click="submitBack()"
        >Mint your story
      </el-button>
    </el-dialog>
  </div>
</template>

<script>
import { ElMessageBox } from "element-plus";
import WriterBase from "@/components/writer/WriterBase";

export default {
  name: "WriterDialog",
  components: { WriterBase },
  props: [
    "IsDialogVisible",
    "IsWriterDisabled",
    "DialogTitle",
    "triggerAction",
  ],
  emits: ["triggerAction", "update:IsDialogVisible"],
  data() {
    return {
      dialogTitle: "",
      dialogText: "",
    };
  },
  computed: {
    SizeInBytes() {
      return Buffer.from(this.dialogTitle).length;
    },
  },
  methods: {
    handleClose(done) {
      ElMessageBox.confirm(
        "Are you sure to close this ? Changes will not be saved!"
      )
        .then(() => {
          done();
        })
        .catch(() => {
          // catch error
        });
    },
    submitBack() {
      this.$emit("triggerAction", this.dialogTitle, this.dialogText);
    },
  },
};
</script>

<style scoped>
.notify-text-size {
  text-align: right;
  text-justify: none;
  font-size: 13px;
  color: red;
}

.WriterDialog {
  text-align: left;
}
</style>
