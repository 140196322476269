import { createStore } from "vuex";
import walletState from "./walletState";
import contract from "@/store/contract";
import InitHandler from "@/store/handler/initHandler";

const { ethers } = require("ethers");

export default createStore({
  state: {
    walletState: walletState,
    contract: contract,
    walletManager: {},
    blockStoryManager: {},
    proposalBlockStoryManager: {},
    receivedProposalManager: {},
    sentProposalManager: {},
    transactionManager: {},
    storyExplorer: {},
    connectionProvider: {},
    storyListManager: {},
    storyExpensiveListManager: {},
    contractService: {},
    transactionService: {},
    errorService: {},
    contractRepo: {},
    explorerService: {},
    activePollers: {},
    bidManager: {},
    bidService: {},
    bidRepo: {},
    graphService: {},
    graphRepo: {},
    proposalService: {},
    proposalRepo: {},
    voteService: {},
    voteRepo: {},
    initialized: false,
  },
  mutations: {
    initializeApp(state) {
      state.initialized = true;
    },
    updateMintCost(state, { val }) {
      state.contractRepo.availableMintsLeft = val;
    },
  },
  actions: {
    async initializeEthers({ state }) {
      if (state.initialized) {
        return;
      }

      const initHandler = new InitHandler(state);
      // sets the current address + the contract resolver
      await initHandler.InitCurrentAddress();

      // initializes the rest of the libraries
      initHandler.InitLibraries(this);

      // ensure the provider is connected to the correct network
      await initHandler.EnsureCorrectNetwork();

      // starts the polling services
      this.state.activePollers.Start();

      // mark the state as initialized
      this.commit("initializeApp");
    },

    async pollWeb3Change({ state }) {
      if (state.walletState.ActiveAccountPolling) {
        return;
      }
      const closure = this;
      state.walletState.ActiveAccountPolling = true;
      setInterval(async () => {
        const signer = state.walletState.provider.getSigner();
        let currentAddress = await signer.getAddress();
        if (state.walletState.CurrentAddress !== currentAddress) {
          state.walletState.CurrentAddress = currentAddress;
          console.log("Address changed to: %s", currentAddress);
          state.initialized = false;
          closure.dispatch("initializeEthers");
        }
      }, 1000);
    },

    async mintStory({ state }, { mintTitle, mintText }) {
      return await state.contractService.MintStory(mintTitle, mintText);
    },

    async reserveStory({ state }) {
      return await state.contractService.ReserveStory();
    },

    // fetches the initial state of the contract
    async updateBlockStory({ state }, { storyId, choiceId, choiceType }) {
      if (choiceType === "Proposal") {
        return await state.blockStoryManager.SetProposalAsActiveBlock(
          storyId,
          choiceId
        );
      }
      await state.blockStoryManager.SetActiveBlock(storyId, choiceId);
    },

    async issueProposalVote({ state }, { propId, voteAmount }) {
      return await state.contractService.IssueProposalVote(propId, voteAmount);
    },

    async issueTokenBid({ state }, { storyId, bidAmount }) {
      return await state.contractService.IssueTokenBid(storyId, bidAmount);
    },
    // fetches the initial state of the contract
    async proposeNewLinkage(
      { state },
      { storyId, proposedTitle, proposedText, bidAmount }
    ) {
      try {
        const derp = await state.contractService.ProposeNewLinkage(
          storyId,
          proposedTitle,
          proposedText,
          bidAmount
        );
        return derp;
      } catch (e) {
        console.log("error2 %s: ", e);
      }

      return null;
    },
    // accepts the given proposal
    async acceptBid({ state }, { bidId }) {
      return await state.contractService.AcceptBid(bidId);
    },
    // accepts the given proposal
    async cancelBid({ state }, { bidId }) {
      let tx = await state.contract.contractObj.cancelBid(bidId);
      // todo what if it gets rejected / fails ?
      state.transactionManager.AddTransaction(tx, "Cancelled story bid");
      state.walletManager.RemoveSentBid(bidId);
    },
    // accepts the given proposal
    async acceptProposal({ state }, { storyId, proposalId }) {
      return await state.contractService.AcceptProposal(storyId, proposalId);
    },
    // cancels a previously sent proposal
    async cancelProposal({ state }, { proposalId }) {
      let tx = await state.contract.contractObj.cancelProposal(proposalId);
      // todo what if it gets rejected / fails ?
      state.transactionManager.AddTransaction(tx, "Cancel story link");
      state.sentProposalManager.RemoveProposal(proposalId);
    },

    async reloadOwnedStories({ state }) {
      await state.walletManager.ReloadOwnedStories();
    },

    async reloadReceivedProposals({ state }) {
      await state.walletManager.ReloadReceivedProposals();
    },

    async reloadSentTokenBids({ state }) {
      await state.walletManager.ReloadSentTokenBids();
    },

    async reloadSentVotes({ state }) {
      await state.voteService.GetCurrentAddressSentVotes();
    },

    async reloadSentProposals({ state }) {
      await state.walletManager.ReloadSentProposals();
    },

    async reloadWalletBalance({ state }) {
      await state.walletManager.ReloadWalletBalance();
    },

    async walletWithdraw({ state }) {
      return await state.walletManager.WalletWithdraw();
    },

    async reloadMintPrices({ state }) {
      await state.contractService.ReloadMintCost();
    },

    async finalizeMint(
      { state },
      { reservationId, finalizedTitle, finalizedText }
    ) {
      await state.contractService.FinalizeMint(
        reservationId,
        finalizedTitle,
        finalizedText
      );
    },

    async changeSellPrice({ state }, { storyId, sellPrice }) {
      return await state.contractService.SetSellPrice(
        storyId,
        ethers.utils.parseEther(sellPrice.toString())
      );
    },

    async reloadExplorerStories({ state }) {
      state.storyListManager.initialLoad = true;
      state.storyExpensiveListManager.initialLoad = true;
      await state.explorerService.UpdateLatestStories();
      await state.explorerService.UpdateMostExpensiveStories();
      state.storyListManager.initialLoad = false;
      state.storyExpensiveListManager.initialLoad = false;
    },
  },
  modules: {},
});
