<template>
  <el-row>
    <el-col :span="4"></el-col>
    <el-col :span="16">
      <reader :StoryId="storyId" OperationButtonVisible="true"></reader>
    </el-col>
    <el-col :span="4"></el-col>
  </el-row>
</template>

<script>
import Reader from "@/components/Reader";

export default {
  name: "BlockStory",
  props: ["storyId", "proposalId"],
  components: { Reader },
  computed: {},
  methods: {},

  async mounted() {
    await this.$store.dispatch("initializeEthers");
    await this.$store.dispatch("updateBlockStory", {
      parentId: undefined,
      choiceId: this.storyId,
    });
  },
};
</script>

<style>
.el-timeline {
  padding-right: 20px;
}

.el-timeline-item__timestamp {
  color: var(--el-text-color-secondary);
  line-height: 1;
  font-size: var(--el-font-size-small);
  text-align: left;
}

.el-timeline-item__wrapper:last-of-type {
  ref: "top";
}
</style>
