import gql from "graphql-tag";
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import Config from "../config";
import { InMemoryCache } from "apollo-cache-inmemory";

class GraphRepo {
  constructor(state) {
    const defaultOptions = {
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
    };
    this.client = new ApolloClient({
      // Provide the URL to the API server.
      link: new HttpLink({
        uri: Config.TheGraphAddress,
      }),
      // Using a cache for blazingly
      // fast subsequent queries.
      cache: new InMemoryCache(),
      defaultOptions: defaultOptions,
    });

    this.state = state;
    this.storyBids = {};
    this.sentTokenBids = {};
    this.sentProposals = {};
    this.currentStories = {};
    this.receivedProposal = {};
    this.expensiveStories = {};
    this.latestStories = {};
    this.storyProposals = {};
    this.proposalVotes = {};
    this.addressSentVotes = {};
  }

  // GetAddressStories returns the stories of the address
  async GetAddressStories(address) {
    const response = await this.client.query({
      query: gql`
        query ($ownerId: String!) {
          ownedTokenEntities(where: { to: $ownerId }) {
            storyId
          }
        }
      `,
      variables: {
        ownerId: address,
      },
    });
    this.currentStories[address] = response.data.ownedTokenEntities;
    return this.currentStories[address];
  }

  SyncGetAddressStories(address) {
    return this.currentStories[address];
  }

  // GetAddressReceivedProposals returns the received proposals for address
  async GetAddressReceivedProposals(address) {
    const ownedStories = await this.GetAddressStories(address);
    const ownedStoriesId = [];
    for (const ownedStoryId of ownedStories) {
      ownedStoriesId.push(ownedStoryId.storyId);
    }

    // fetch proposals filtered by ownedStories
    const response = await this.client.query({
      query: gql`
        query GetReceivedProposedStories($ownedStories: [String!]) {
          bidProposalEntities(
            where: { active: true, storyId_in: $ownedStories }
          ) {
            id
            owner
            storyId
            propId
            active
          }
        }
      `,
      variables: {
        ownedStories: ownedStoriesId,
      },
    });
    this.receivedProposal[address] = response.data.bidProposalEntities;
    return this.receivedProposal[address];
  }

  async SyncGetAddressReceivedProposals(address) {
    return this.receivedProposal[address];
  }

  // GetStoryBids returns the bids of a story
  async GetStoryBids(storyId) {
    const response = await this.client.query({
      query: gql`
        query ($story: String!) {
          bidTokenEntities(where: { active: true, storyId: $story }) {
            storyId
            bidId
            owner
          }
        }
      `,
      variables: {
        story: storyId.toString(),
      },
    });
    this.storyBids[storyId] = response.data.bidTokenEntities;
    return this.storyBids[storyId];
  }

  SyncGetStoryBids(storyId) {
    return this.storyBids[storyId];
  }

  // GetStoryProposals returns the proposals of a story
  async GetStoryProposals(storyId) {
    const response = await this.client.query({
      query: gql`
        query ($story: String!) {
          bidProposalEntities(where: { active: true, storyId: $story }) {
            storyId
            propId
            owner
          }
        }
      `,
      variables: {
        story: storyId,
      },
    });
    this.storyProposals[storyId] = response.data.bidProposalEntities;
    return this.storyProposals[storyId];
  }

  SyncGetStoryProposals(storyId) {
    return this.storyProposals[storyId];
  }

  // GetAddressSentBids returns the bids sent by an address
  async GetAddressSentBids(address) {
    const response = await this.client.query({
      query: gql`
        query ($owner: String!) {
          bidTokenEntities(where: { active: true, owner: $owner }) {
            storyId
            bidId
          }
        }
      `,
      variables: {
        owner: address,
      },
    });
    this.sentTokenBids[address] = response.data.bidTokenEntities;
    return this.sentTokenBids[address];
  }

  SyncGetAddressSentBids(address) {
    return this.sentTokenBids[address];
  }

  // GetAddressSentProposals returns the proposals sent by an address
  async GetAddressSentProposals(address) {
    const response = await this.client.query({
      query: gql`
        query GetSentProposedStories($owner: String!) {
          bidProposalEntities(where: { owner: $owner, active: true }) {
            id
            owner
            storyId
            propId
            active
          }
        }
      `,
      variables: {
        owner: address,
      },
    });
    this.sentProposals[address] = response.data.bidProposalEntities;
    return this.sentProposals[address];
  }

  SyncGetAddressSentProposals(address) {
    return this.sentProposals[address];
  }

  // GetLatestStories returns the latest stories
  async GetLatestStories() {
    const response = await this.client.query({
      query: gql`
        query GetRandomStories {
          ownedTokenEntities(where: { isReserved: false }) {
            storyId
            amount
            txHash
            createdAtBN
          }
        }
      `,
    });
    this.latestStories = response.data.ownedTokenEntities;
    return this.latestStories;
  }

  SyncGetLatestStories() {
    return this.latestStories;
  }

  // GetMostExpensiveStories returns the most expensive stories
  async GetMostExpensiveStories() {
    const response = await this.client.query({
      query: gql`
        query GetRandomStories {
          ownedTokenEntities(
            where: { isReserved: false }
            orderBy: amount
            orderDirection: desc
          ) {
            storyId
            amount
            txHash
            createdAtBN
          }
        }
      `,
    });
    this.expensiveStories = response.data.ownedTokenEntities;
    return this.expensiveStories;
  }

  SyncGetMostExpensiveStories() {
    return this.expensiveStories;
  }

  // GetProposalVotes returns the votes of a proposal
  async GetProposalVotes(proposalId) {
    const response = await this.client.query({
      query: gql`
        query ($propId: String!) {
          voteProposalEntities(where: { active: true, propId: $propId }) {
            owner
            voteId
            amount
          }
        }
      `,
      variables: {
        propId: proposalId,
      },
    });
    this.proposalVotes[proposalId] = response.data.voteProposalEntities;
    return this.proposalVotes[proposalId];
  }

  SyncGetProposalVotes(proposalId) {
    return this.proposalVotes[proposalId];
  }

  // GetAddressSentVotes returns the votes of an address
  async GetAddressSentVotes(address) {
    const response = await this.client.query({
      query: gql`
        query ($owner: String!) {
          voteProposalEntities(where: { active: true, owner: $owner }) {
            voteId
            amount
          }
        }
      `,
      variables: {
        owner: address,
      },
    });
    this.addressSentVotes[address] = response.data.voteProposalEntities;
    return this.addressSentVotes[address];
  }

  SyncGetAddressSentVotes(address) {
    return this.addressSentVotes[address];
  }
}

export default GraphRepo;
