<template>
  <el-card shadow="never">
    <template #header>
      <div class="card-header">
        <span>Sponsor this story</span>
      </div>
    </template>
    <div>
      Bid so far: {{ this.propAmount }}
      <p></p>
      Sponsor amount:
      <el-input-number
        v-model="bidAmount"
        :disabled="isInputProposalDisabled"
        :precision="2"
        :step="0.1"
      />
      <div v-if="bidAmount <= 0" class="notify-text-size">
        Not enough to make a bid.
      </div>

      <div style="text-align: right">
        <el-button
          :disabled="isInputProposalDisabled"
          plain
          type="warning"
          v-on:click="issueVote()"
          >Sponsor this proposal
        </el-button>
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "SponsorProposal",
  props: ["BlockStoryId"],
  data() {
    return {
      isInputProposalDisabled: false,
      bidAmount: 1,
      storySellPrice: -1,
    };
  },
  computed: {
    getStory() {
      if (!this.$store.state.initialized) {
        return null;
      }
      return this.$store.state.proposalService.SyncGetProposal(
        this.BlockStoryId
      );
    },
    propAmount() {
      if (this.getStory === null) {
        return "No price set.";
      }

      return this.getStory.StringBidAmount + "AVAX";
    },
  },
  methods: {
    async issueVote() {
      this.isInputProposalDisabled = true;
      await this.$store.dispatch("issueProposalVote", {
        propId: this.BlockStoryId,
        voteAmount: this.bidAmount,
      });
      this.isInputProposalDisabled = false;
    },
  },
};
</script>

<style scoped>
.notify-text-size {
  text-align: left;
  text-justify: none;
  font-size: 13px;
  color: red;
}
</style>
