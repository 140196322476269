<template>
  <el-row class="home-banner regular-text">
    <el-col :span="2"></el-col>
    <el-col :span="20">
      <el-row>
        <el-col :span="14" :xs="24">
          <el-image
            :src="treeImage"
            class="banner-image"
            fit="contain"
          ></el-image>
        </el-col>
        <el-col :span="10" :xs="24" style="text-align: left">
          <p class="banner-title pull-left">
            <span class="color-green">what is </span
            ><span class="color-blue">blockstory</span>
          </p>
          <p>
            A story made out of
            <el-tooltip
              :visible-arrow="false"
              content="A blockstory NFT on the blockchain"
              effect="dark"
              placement="top-start"
              popper-class="popper-home"
            >
              <el-button class="tooltip-text regular-text" type="text"
                >Story-Nodes
              </el-button>
            </el-tooltip>
            forever stored in the blockchain, immutable, uncensored, free.
            <br />
            Play the story by choosing what the next step is!
          </p>

          <ul class="text-bullets">
            <li>
              Each blockstory starts with the mint of a
              <el-tooltip
                :visible-arrow="false"
                content="The first Story-Node of a story"
                effect="dark"
                placement="top-start"
                popper-class="popper-home"
              >
                <el-button class="tooltip-text regular-text" type="text"
                  >Genesis-Node
                </el-button>
              </el-tooltip>
              .
            </li>
            <li>
              Anyone can
              <el-tooltip
                :visible-arrow="false"
                content="Propose new followup to a Story-Node."
                effect="dark"
                placement="top-start"
                popper-class="popper-home"
              >
                <el-button class="tooltip-text regular-text" type="text"
                  >Propose
                </el-button>
              </el-tooltip>
              a new followup by issuing a
              <el-tooltip
                :visible-arrow="false"
                content="A blockstory entry that can be accepted by the parent story"
                effect="dark"
                placement="top-start"
                popper-class="popper-home"
              >
                <el-button class="tooltip-text regular-text" type="text"
                  >Proposal-Node
                </el-button>
              </el-tooltip>
              .
            </li>
            <li>
              Anyone can
              <el-tooltip
                :visible-arrow="false"
                content="A vote to incentivize the proposal acceptance"
                effect="dark"
                placement="top-start"
                popper-class="popper-home"
              >
                <el-button class="tooltip-text regular-text" type="text"
                  >Sponsor
                </el-button>
              </el-tooltip>
              a new proposal.
            </li>
            <li>
              Accepted
              <el-tooltip
                :visible-arrow="false"
                content="Both Proposal-owner and Sponsor will share future profits"
                effect="dark"
                placement="top-start"
                popper-class="popper-home"
              >
                <el-button class="tooltip-text regular-text" type="text"
                  >Proposal-Nodes
                </el-button>
              </el-tooltip>
              will become
              <el-tooltip
                :visible-arrow="false"
                content="The NFT-entry of the blockstory"
                effect="dark"
                placement="top-start"
                popper-class="popper-home"
              >
                <el-button class="tooltip-text regular-text" type="text"
                  >Story-Nodes
                </el-button>
              </el-tooltip>
              .
              <br />
              Able to receive proposals and forever chained to the story.
            </li>
            <li>Each Story-Node is an authoritative, sellable NFT.</li>
          </ul>
        </el-col>
      </el-row>
    </el-col>
    <el-col :span="2"></el-col>
  </el-row>
</template>

<script>
export default {
  name: "WhatIsBlockstory",
  data() {
    return {
      treeImage: require("@/assets/imgs/elements/blockstory-banner.png"),
    };
  },
};
</script>

<style scoped>
.text-bullets > li {
  padding-bottom: 1.1rem;
}
</style>
