<template>
  <div>
    <div style="text-align: left">
      <span ref="TitleFocusTarget">Title:</span>
    </div>
    <p></p>
    <el-input
      :disabled="IsWriterDisabled"
      :model-value="InputTitle"
      placeholder="This is the story of.."
      type="text"
      @focus="onTitleFocus"
      @update:modelValue="this.$emit('update:InputTitle', $event)"
    />
    <div v-if="SizeInBytes > 160" class="notify-text-size">
      Too many bytes on the title: {{ SizeInBytes }}/160
    </div>
    <p></p>
    <el-row>
      <el-col :span="4"><span ref="TextFocusTarget">Text:</span></el-col>
      <el-col :span="16"></el-col>
      <el-col :span="4">
        <format-helper></format-helper>
      </el-col>
    </el-row>

    <p></p>
    <el-tabs type="border-card">
      <el-tab-pane label="Edit">
        <input-writer
          ref="textWriter"
          :FocusRef="this.$refs.TextFocusTarget"
          :InputWriterText="InputText"
          :IsWriterDisabled="IsWriterDisabled"
          @update:InputWriterText="this.$emit('update:InputText', $event)"
        ></input-writer>
      </el-tab-pane>
      <el-tab-pane label="Preview">
        <el-card
          :style="{
            overflow: 'scroll',
            height: this.TextWriterHeight() + 'px',
          }"
          shadow="never"
        >
          <MarkdownViewer :source="InputText" />
        </el-card>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import InputWriter from "@/components/writer/InputWriter";
import FormatHelper from "@/components/format/FormatHelper";
import MarkdownViewer from "@/components/reader/MarkdownViewer";

export default {
  name: "WriterBase",
  props: ["InputTitle", "InputText", "IsWriterDisabled"],
  components: { InputWriter, FormatHelper, MarkdownViewer },
  data() {
    return {
      dialogTitle: "",
      dialogText: "",
      popOverVisible: false,
      availableIcons: [],
    };
  },
  computed: {
    SizeInBytes() {
      return Buffer.from(this.InputTitle).length;
    },
  },
  methods: {
    TextWriterHeight() {
      let wri = this.$refs.textWriter;
      if (!wri || wri.$el.nextElementSibling.clientHeight < 1) {
        return 1;
      }
      return wri.$el.nextElementSibling.clientHeight;
    },

    onTitleFocus() {
      this.$nextTick(() => {
        this.$refs.TitleFocusTarget.scrollIntoView({
          behavior: "smooth",
        });
      });
    },
  },
};
</script>

<style scoped>
.notify-text-size {
  text-align: right;
  text-justify: none;
  font-size: 13px;
  color: red;
}
</style>
