import { ethers } from "ethers";

class ContractService {
  constructor(state) {
    this.state = state;
  }

  async ReserveStory() {
    return await this.state.transactionService.Execute(async () => {
      await this.ReloadMintCost();
      return this.state.contract.contractObj.mint(true, "", "", {
        value: ethers.utils.parseEther(this.state.contractRepo.MintCost),
      });
    }, "Reserve Mint");
  }

  async MintStory(mintTitle, mintText) {
    return await this.state.transactionService.Execute(async () => {
      await this.ReloadMintCost();
      return this.state.contract.contractObj.mint(false, mintTitle, mintText, {
        value: ethers.utils.parseEther(this.state.contractRepo.MintCost),
      });
    }, "Mint");
  }

  async AcceptBid(bidId) {
    return await this.state.transactionService.Execute(async () => {
      return this.state.contract.contractObj.acceptBid(bidId);
    }, "Accept Proposal");
  }

  async ReloadMintCost() {
    let mintCost = "";
    let availableMints = 0;
    try {
      availableMints =
        await this.state.contract.contractObj.getAvailableMints();
      if (availableMints.gte(5000)) {
        mintCost = "1";
      } else if (availableMints.gte(2500)) {
        mintCost = "2";
      } else if (availableMints.gte(1500)) {
        mintCost = "3";
      } else {
        mintCost = "4";
      }
    } catch (e) {
      mintCost = "0";
    }

    this.state.contractRepo.availableMints = availableMints;
    this.state.contractRepo.mintCost = mintCost;
  }

  async FinalizeMint(reservationId, title, text) {
    return await this.state.transactionService.Execute(async () => {
      return this.state.contract.contractObj.finalizeMint(
        reservationId,
        title,
        text
      );
    }, "Finalize Mint");
  }

  async SetSellPrice(storyId, sellPrice) {
    return await this.state.transactionService.ExecuteMultiple(
      async () => {
        return this.state.contract.contractObj.approve(
          this.state.contract.Address,
          storyId
        );
      },
      async () => {
        return this.state.contract.contractObj.setSellPrice(storyId, sellPrice);
      },
      "Approve Token Sale",
      "Set Sell Price"
    );
  }

  async IssueProposalVote(propId, voteAmount) {
    await this.state.transactionService.Execute(async () => {
      return this.state.contract.contractObj.voteProposal(propId, {
        value: ethers.utils.parseEther(voteAmount.toString()),
      });
    }, "Issue Proposal Sponsorship");
  }

  async IssueTokenBid(storyId, bidAmount) {
    await this.state.transactionService.Execute(async () => {
      return this.state.contract.contractObj.bidToken(storyId, {
        value: ethers.utils.parseEther(bidAmount.toString()),
      });
    }, "Issue Token Bid");
  }

  async ProposeNewLinkage(storyId, proposedTitle, proposedText, bidAmount) {
    return await this.state.transactionService.Execute(async () => {
      return this.state.contract.contractObj.bidProposal(
        storyId,
        proposedTitle,
        proposedText,
        {
          value: ethers.utils.parseEther(bidAmount.toString()),
        }
      );
    }, "Propose link story");
  }

  async AcceptProposal(storyId, proposalId) {
    return await this.state.transactionService.Execute(async () => {
      return this.state.contract.contractObj.acceptProposal(
        storyId,
        proposalId
      );
    }, "Accept Proposal");
  }

  async CancelVote(voteId) {
    return await this.state.transactionService.Execute(async () => {
      return this.state.contract.contractObj.cancelVote(voteId);
    }, "Cancel Vote");
  }

  async ClaimFarm(farmerId) {
    return await this.state.transactionService.Execute(async () => {
      return this.state.contract.contractObj.claimFarm(farmerId);
    }, "Claim Sponsorship");
  }

  async GetFarmValue(farmerId) {
    return await this.state.contract.contractObj.getFarmValue(farmerId);
  }
}

export default ContractService;
