import Proposal from "@/lib/models/proposal";

class ProposalRepo {
  constructor(state) {
    this.state = state;
    this.proposals = {};
  }

  ResetProposalsForStory(storyId) {
    for (const proposalId in this.proposals) {
      if (this.proposals[proposalId].ParentStoryId === storyId) {
        delete this.proposals[proposalId];
      }
    }
  }

  async GetProposal(propId) {
    if (this.proposals[propId] !== undefined) {
      return this.proposals[propId];
    }
    const proposal = await this.state.contract.contractObj.getProposal(propId);
    const story = await this.state.storyCacheService.GetStory(proposal.storyId);
    if (isNaN(propId)) {
      console.log(propId);
    }
    this.proposals[propId] = new Proposal(propId, proposal, story);
    return this.proposals[propId];
  }

  SyncGetProposal(propId) {
    return this.proposals[propId];
  }
}

export default ProposalRepo;
