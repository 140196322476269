class ConnectionProvider {
  constructor() {
    this.connections = {};
    this.connections["local"] = {
      chainId: "0xa868",
      chainName: "Avalanche Local C-Chain",
      nativeCurrency: {
        name: "Avalanche",
        symbol: "AVAX",
        decimals: 18,
      },
      rpcUrls: ["http://localhost:9660/ext/bc/C/rpc"],
      blockExplorerUrls: ["https://cchain.explorer.avax-test.network/"],
    };
    this.connections["fuji"] = {
      chainId: "0xa869",
      chainName: "Avalanche Local C-Chain",
      nativeCurrency: {
        name: "Avalanche",
        symbol: "AVAX",
        decimals: 18,
      },
      rpcUrls: ["http://localhost:9660/ext/bc/C/rpc"],
      blockExplorerUrls: ["https://cchain.explorer.avax-test.network/"],
    };
  }

  GetChainConfig(chainId) {
    return this.connections[chainId];
  }
}

export default ConnectionProvider;
