import { createRouter, createWebHistory } from "vue-router";
import BlockStory from "../views/BlockStory";
import Home from "@/views/Home";
import Wallet from "@/views/Wallet";
import Mint from "@/views/Mint";
import OwnedStories from "@/components/wallet/OwnedStories";
import Explorer from "@/views/Explorer";

function routeBlockStory(route) {
  if (route.params.story === "" || route.params.proposal === "") {
    return {
      storyId: 0,
      proposalId: undefined,
    };
  }

  return {
    storyId: parseInt(route.params.story),
    proposalId: parseInt(route.params.proposal),
  };
}

function routeTokenBids(route) {
  if (route.params.ownedStoryId === "") {
    return { ownedStoryId: undefined };
  }

  return {
    ownedStoryId: parseInt(route.params.ownedStoryId),
  };
}

const routes = [
  {
    path: "/s/:story?",
    name: "BlockStory",
    component: BlockStory,
    children: [
      {
        path: "p/:proposal?",
        name: "BlockStory",
        component: BlockStory,
      },
    ],
    props: routeBlockStory,
  },
  {
    path: "/explorer",
    name: "Explorer",
    component: Explorer,
  },
  {
    path: "/w/",
    name: "Wallet",
    component: Wallet,
    children: [
      {
        path: "p/:ownedStoryId?",
        component: OwnedStories,
      },
    ],
    props: routeTokenBids,
  },
  {
    path: "/m/:reservedStoryId?",
    name: "Mint",
    component: Mint,
  },
  {
    path: "",
    name: "Home",
    component: Home,
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
