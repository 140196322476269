import CurrentTransaction from "@/lib/models/currentTransaction";
import TxOperation from "@/lib/models/txOperation";

class TransactionService {
  constructor(state) {
    this.state = state;
    this.currentTransaction = undefined;
  }

  async Execute(asyncFunc, name) {
    let txOperation = new TxOperation();
    try {
      this.currentTransaction = new CurrentTransaction(name);
      let tx = asyncFunc();

      const awaitedTx = await this.catchError(tx);

      if (awaitedTx.err !== undefined) {
        this.currentTransaction.SetError(awaitedTx.err);
        txOperation.AddError(awaitedTx.err);
        return txOperation;
      }

      tx = awaitedTx.ret;
      txOperation.AddTx(tx);
      this.currentTransaction.UpdateTx(tx);
      this.state.transactionManager.AddTransaction(tx, name);
    } catch (e) {
      console.log("error: %s", e);
      this.currentTransaction.SetError(e);
      txOperation.AddError(e);
    }
    return txOperation;
  }

  async ExecuteMultiple(asyncFunc1, asyncFunc2, name1, name2) {
    let tx;
    try {
      tx = asyncFunc1();
      this.currentTransaction = new CurrentTransaction(name1);
      tx = await tx;
      this.currentTransaction.UpdateTx(tx);
      this.state.transactionManager.AddTransaction(tx, name1);

      tx = asyncFunc2();
      this.currentTransaction = new CurrentTransaction(name2);
      tx = await tx;
      this.currentTransaction.UpdateTx(tx);
      this.state.transactionManager.AddTransaction(tx, name2);
    } catch (e) {
      console.log("error: %s", e);
      this.currentTransaction.SetError(e);
    }
    return tx;
  }

  async catchError(promise) {
    try {
      const awaitedReturn = await promise;
      return { ret: awaitedReturn, err: undefined };
    } catch (e) {
      console.log("await error: %s", e);
      return { ret: undefined, err: e };
    }
  }

  GetTransactionAction() {
    if (this.currentTransaction === undefined) {
      return { Visible: false };
    }
    return this.currentTransaction;
  }

  CloseVisibility() {
    if (this.currentTransaction === undefined) {
      return;
    }
    this.currentTransaction.visible = false;
  }
}

export default TransactionService;
