<template>
  <el-row>
    <el-col :span="4">
      <router-link style="float: left" to="/">
        <el-button style="padding-top: 0px" type="text">
          <div style="color: white; position: absolute">alpha</div>
          <el-image
            :src="logoImage"
            fit="contain"
            style="height: 90px; margin-top: -15px"
          >
          </el-image>
        </el-button>
      </router-link>
    </el-col>
    <el-col :span="16">
      <el-col :span="14" />
      <el-col :span="2">
        <router-link to="/explorer/">
          <el-button type="text"> EXPLORER</el-button>
        </router-link>
      </el-col>
      <el-col :span="2">
        <router-link to="/m/">
          <el-button type="text">MINT</el-button>
        </router-link>
      </el-col>
      <el-col :span="2">
        <router-link to="/w/">
          <el-button type="text">WALLET</el-button>
        </router-link>
      </el-col>
      <el-col :span="4"></el-col>
    </el-col>
    <el-col :span="4">
      <wallet-status></wallet-status>
    </el-col>
  </el-row>
  <transaction-execution></transaction-execution>
</template>

<script>
import WalletStatus from "@/components/WalletStatus";
import TransactionExecution from "@/components/TransactionExecution";

export default {
  name: "NavBar",
  components: { TransactionExecution, WalletStatus },
  data() {
    return {
      logoImage: require("@/assets/imgs/blockstory_neg.png"),
    };
  },
  computed: {},
};
</script>

<style lang="scss"></style>
