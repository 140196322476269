import Bid from "@/lib/models/bid";

class BidRepo {
  constructor(state) {
    this.state = state;
    this.bids = {};
  }

  ResetBidsForStory(storyId) {
    for (const bidId in this.bids) {
      if (this.bids[bidId].ParentStoryId === storyId) {
        delete this.bids[bidId];
      }
    }
  }

  async GetBid(bidId) {
    if (this.bids[bidId] !== undefined) {
      return this.bids[bidId];
    }

    let bid = await this.state.contract.contractObj.getBid(bidId);
    let story = await this.state.storyCacheService.GetStory(bid.storyId);
    this.bids[bidId] = new Bid(bidId, bid, story);
    return this.bids[bidId];
  }

  SyncGetBid(bidId) {
    return this.bids[bidId];
  }
}

export default BidRepo;
