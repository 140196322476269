class BidService {
  constructor(state) {
    this.state = state;
  }

  ResetBidsForStory(storyId) {
    this.state.bidRepo.ResetBidsForStory(storyId);
  }

  async GetBid(bidId) {
    return await this.state.bidRepo.GetBid(bidId);
  }

  SyncGetBid(bidId) {
    return this.state.bidRepo.SyncGetBid(bidId);
  }

  async GetBidsForStory(storyId) {
    const bids = [];
    const gBids = await this.state.graphService.GetStoryBids(storyId);
    for (const gBid of gBids) {
      bids.push(await this.GetBid(gBid.bidId));
    }
    return bids;
  }

  SyncGetBidsForStory(storyId) {
    const bids = [];
    const gBids = this.state.graphService.SyncGetStoryBids(storyId);
    for (const gBid of gBids) {
      bids.push(this.SyncGetBid(gBid.bidId));
    }
    return bids;
  }

  async ReloadBids(storyId) {
    this.ResetBidsForStory(storyId);
    await this.GetBidsForStory(storyId);
  }
}

export default BidService;
