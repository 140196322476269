<template>
  <el-popover
    placement="bottom"
    title="Format Helper"
    trigger="click"
    width="40vw"
  >
    <template #reference>
      <el-button class="formattingBtn" size="mini">Formatting</el-button>
    </template>
    <el-tabs type="border-card">
      <el-tab-pane label="Icons">
        <format-playground v-model:DisplayText="icons" />
      </el-tab-pane>
      <el-tab-pane label="Headers">
        <format-playground v-model:DisplayText="headers" />
      </el-tab-pane>
      <el-tab-pane label="Tables">
        <format-playground v-model:DisplayText="tables" />
      </el-tab-pane>
      <el-tab-pane label="Lists">
        <format-playground v-model:DisplayText="enums" />
      </el-tab-pane>
    </el-tabs>

    <div style="text-align: right; margin: 0">
      <el-button size="mini" type="text" @click="visible = false"
        >close
      </el-button>
    </div>
  </el-popover>
</template>

<script>
import FormatPlayground from "@/components/format/FormatPlayground";

export default {
  name: "FormatHelper",
  components: { FormatPlayground },
  data() {
    return {
      headers: `# h1
## h2
### h3
#### h4
##### h5
###### h6`,
      icons: `## Icons

Huge list of icons to use.

Just try writing ":smi ".

Can use both  :) or :smile: or the unicode 😄 !

`,
      text: `## Text

~~strike~~ -  _italic_ - **bold** - super^script^`,
      enums: `## Enumerations
      
1. One
2. Two
3. Three

* Start a line with a star
- Or with a dash
  * Add two spaces for a subpoint
    * You can use a start
    - Or a Dash`,
      tables: `## Tables
      
First Header | Second Header
------------ | -------------
Cell 1 | Cell 2
Row 2 :thumbsup: Cell 1| Row 2 :guard: Cell 2`,
    };
  },
};
</script>

<style scoped>
.el-popover.el-popper.is-light {
  max-height: 20vh;
}

.formattingBtn {
  float: right;
}
</style>
