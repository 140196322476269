<template>
  <el-card shadow="never">
    <template #header>
      <div class="card-header">
        <span>Propose a new Choice</span>
      </div>
    </template>
    <writer-base
      v-model:InputText="proposedText"
      v-model:InputTitle="proposedTitle"
      :IsWriterDisabled="isInputProposalDisabled"
    ></writer-base>
    <div style="float: right">
      <el-input-number
        v-model="bidAmount"
        :disabled="isInputProposalDisabled"
        :precision="2"
        :step="0.1"
      />
      <el-button
        :disabled="isInputProposalDisabled"
        plain
        type="warning"
        v-on:click="this.proposeNewBlockStory()"
        >Propose a choice
      </el-button>
    </div>
  </el-card>
</template>

<script>
import WriterBase from "@/components/writer/WriterBase";

export default {
  name: "StoryProposalAction",
  components: { WriterBase },
  props: ["BlockStoryId"],
  data() {
    return {
      proposedTitle: "",
      proposedText: "",
      isInputProposalDisabled: false,
      isInputBidDisabled: false,
      bidAmount: 1,
    };
  },
  computed: {
    SizeInBytes() {
      return Buffer.from(this.proposedTitle).length;
    },
  },
  methods: {
    async proposeNewBlockStory() {
      this.isInputProposalDisabled = true;
      await this.$store.dispatch("proposeNewLinkage", {
        storyId: this.BlockStoryId,
        proposedTitle: this.proposedTitle,
        proposedText: this.proposedText,
        bidAmount: this.bidAmount,
      });
      this.isInputProposalDisabled = false;
    },
  },
};
</script>

<style scoped>
.notify-text-size {
  text-align: right;
  text-justify: none;
  font-size: 13px;
  color: red;
}
</style>
