<template>
  <div>
    <el-card shadow="never">
      <el-table
        :data="OtherBidsData"
        :v-loading="isLoading"
        size="mini"
        style="overflow: scroll"
      >
        <el-table-column label="Bid" prop="Id" sortable />
        <el-table-column
          label="Bid (AVAX)"
          prop="StringBidAmount"
          sortable
          width="150"
        />
        <el-table-column
          fixed="right"
          label="Address"
          prop="TruncatedOwner"
          width="100%"
        />
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "SentBidsList",
  props: ["StoryId"],
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    OtherBidsData() {
      if (!this.$store.state.initialized) {
        this.loading();
        return [];
      }

      this.ready();
      return this.$store.state.bidService.SyncGetBidsForStory(this.StoryId);
    },
  },
  methods: {
    loading() {
      this.isLoading = true;
    },
    ready() {
      this.isLoading = false;
    },
  },
  async mounted() {
    this.loading();
    await this.$store.state.bidService.ReloadBids(this.StoryId);
    this.ready();
  },
};
</script>

<style scoped></style>
