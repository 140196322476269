import Proposal from "@/lib/models/proposal";

class ProposalCacheService {
  constructor(state) {
    this.state = state;
    this.proposals = {};
  }

  async GetProposal(propId) {
    if (this.proposals[propId] === undefined) {
      const proposal = await this.state.contract.contractObj.getProposal(
        propId
      );
      const story = await this.state.storyCacheService.GetStory(
        proposal.storyId
      );
      this.proposals[propId] = new Proposal(propId, proposal, story);
      return this.proposals[propId];
    }

    return this.proposals[propId];
  }
}

export default ProposalCacheService;
