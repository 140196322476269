import OwnedStory from "@/lib/models/ownedStory";
import { ethers } from "ethers";

class WalletManager {
  constructor(state) {
    this.state = state;
    this.balance = 0;
    this.sentBids = {};
    this.ownedStories = {};
    this.sentProposals = {};
    this.receivedProposals = {};
    this.canFetchTokenBids = false;
    this.fetchingReceivedBidsForToken = true;
    this.fetchingSentTokenBinds = true;
    this.fetchingReceivedProposals = true;
    this.fetchingSentProposals = true;
    this.fetchingWalletBalance = true;
  }

  get IsFetchingWalletBalance() {
    return this.fetchingWalletBalance;
  }

  get IsFetchingSentProposals() {
    return this.fetchingSentProposals;
  }

  get IsFetchingReceivedProposals() {
    return this.fetchingReceivedProposals;
  }

  get IsFetchingSentTokenBinds() {
    return this.fetchingSentTokenBinds;
  }

  get IsFetchingReceivedBidsForToken() {
    return this.fetchingReceivedBidsForToken;
  }

  get StringBalance() {
    return ethers.utils.formatEther(this.balance);
  }

  async ReloadWalletBalance() {
    this.fetchingWalletBalance = true;
    this.balance = await this.state.contract.contractObj.getBalance();
    this.fetchingWalletBalance = false;
  }

  async ReloadReceivedProposals() {
    this.fetchingReceivedProposals = true;
    const proposedStories =
      await this.state.graphService.GetAddressReceivedProposals(
        this.state.walletState.CurrentAddress
      );

    this.ResetReceivedProposals();
    for (const proposedStory of proposedStories) {
      const proposal = await this.state.proposalCacheService.GetProposal(
        proposedStory.id
      );
      this.AddReceivedProposal(proposal);
    }
    this.fetchingReceivedProposals = false;
  }

  async ReloadSentProposals() {
    this.fetchingSentProposals = true;
    const sentProposals = await this.state.graphService.GetAddressSentProposals(
      this.state.walletState.CurrentAddress
    );

    this.ResetSentProposals();
    for (const sentProposal of sentProposals) {
      const proposal = await this.state.proposalCacheService.GetProposal(
        sentProposal.id
      );
      this.AddSentProposal(proposal);
    }
    this.fetchingSentProposals = false;
  }

  async ReloadOwnedStories() {
    this.fetchingReceivedBidsForToken = true;
    const ownedTokens = await this.state.graphService.GetAddressStories(
      this.state.walletState.CurrentAddress
    );
    this.ResetOwnedStories();

    for (const ownedToken of ownedTokens) {
      let story = await this.state.storyCacheService.ForceGetStory(
        ownedToken.storyId
      );
      this.AddToken(story);
      let bids = await this.state.bidService.GetBidsForStory(story.Id);
      this.AddReceivedBids(bids);
    }

    this.fetchingReceivedBidsForToken = false;
  }

  async ReloadSentTokenBids() {
    this.fetchingSentTokenBinds = true;
    const sentBids = await this.state.graphService.GetAddressSentBids(
      this.state.walletState.CurrentAddress
    );

    this.ResetSentBids();
    for (const bidToken of sentBids) {
      const bid = await this.state.bidService.GetBid(bidToken.bidId);
      this.AddSentBid(bid);
    }
    this.fetchingSentTokenBinds = false;
  }

  async WalletWithdraw() {
    await this.state.transactionService.Execute(async () => {
      return this.state.contract.contractObj.withdraw();
    }, "Withdraw balance");
    await this.state.walletManager.ReloadWalletBalance();
  }

  ResetOwnedStories() {
    this.ownedStories = {};
  }

  ResetSentBids() {
    this.sentBids = {};
  }

  ResetSentProposals() {
    this.sentProposals = {};
  }

  ResetReceivedProposals() {
    this.receivedProposals = {};
  }

  AddToken(story) {
    this.ownedStories[story.Id] = new OwnedStory(story);
  }

  AddSentBid(bid) {
    this.sentBids[bid.Id] = bid;
  }

  AddSentProposal(proposal) {
    this.sentProposals[proposal.Id] = proposal;
  }

  AddReceivedBids(bids) {
    for (const bid of bids) {
      this.ownedStories[bid.StoryId].bids[bid.Id] = bid;
    }
  }

  AddReceivedProposal(proposal) {
    this.receivedProposals[proposal.Id] = proposal;
  }

  GetProposal(propId) {
    let proposal = this.receivedProposals[propId];
    if (!proposal) {
      proposal = this.sentProposals[propId];
    }
    return proposal;
  }

  GetReceivedProposals() {
    return this.receivedProposals;
  }

  GetWalletBalance() {
    return this.balance;
  }

  GetReceivedBidsForToken(storyId) {
    return this.ownedStories[storyId].bids;
  }

  GetSentBidsForToken() {
    const bids = [];

    for (const bidsKey in this.sentBids) {
      bids.push(this.sentBids[bidsKey]);
    }
    return bids;
  }

  GetSentProposals() {
    return this.sentProposals;
  }

  RemoveSentBid(bidId) {
    delete this.sentBids[bidId];
  }

  GetAllOwnedStories() {
    return this.ownedStories;
  }
}

export default WalletManager;
