<template>
  <div>
    <el-row>
      <el-col :span="12">
        <el-card shadow="never">
          <el-input
            ref="playgroundTextWriter"
            :model-value="DisplayText"
            rows="15"
            type="textarea"
            @update:modelValue="this.$emit('update:DisplayText', $event)"
          ></el-input>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card
          shadow="never"
          style="
             {
              overflow: scroll;
              height: 100%;
            }
          "
        >
          <markdown-viewer :source="DisplayText" />
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import MarkdownViewer from "@/components/reader/MarkdownViewer";

export default {
  name: "FormatPlayground",
  components: { MarkdownViewer },
  props: {
    DisplayText: String,
  },
  emits: ["update:DisplayText"],
  data() {
    return {
      displayText: "",
    };
  },
  methods: {
    TextWriterHeight() {
      let wri = this.$refs.playgroundTextWriter;
      console.log(wri);
      if (wri === undefined || wri.$el.clientHeight < 1) {
        return 20;
      }
      return wri.$el.clientHeight;
    },
  },
};
</script>

<style scoped></style>
