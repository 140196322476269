<template>
  <el-row class="home-banner white-text regular-text">
    <el-col :span="2"></el-col>
    <el-col :span="20">
      <el-row>
        <el-col :span="11" :xs="24" style="text-align: left">
          <p class="banner-title">sponsor</p>
          <p class="banner-title no-top-margin">
            and <span class="banner-green">earn</span>
          </p>
          <p>&nbsp;</p>
          <p>
            More of a Reader than a Writer ? Did that proposal made you all
            fuzzy and warm ?
          </p>
          <p>
            Sponsor your favorite proposals by Voting on them!
            <br />
            Sponsoring entices the parent story owner to accept the proposal.
          </p>

          <p></p>
          <p>
            If the proposal is accepted as a Story-Node, you'll also earn
            passive-income from it's children.
          </p>
        </el-col>
        <el-col :span="2" :xs="0"></el-col>
        <el-col :span="11" :xs="24">
          <p>
            <el-image
              :src="contribImage"
              class="banner-image"
              fit="fill"
              style="
                float: left;
                position: relative;
                left: 0px;
                top: 43px;
                z-index: 1000;
                height: 50vh;
              "
            ></el-image>
          </p>
        </el-col>
      </el-row>
    </el-col>

    <el-col :span="2" :xs="0"></el-col>
  </el-row>
</template>

<script>
export default {
  name: "VotingBanner",
  data() {
    return {
      contribImage: require("@/assets/imgs/elements/earn-banner.png"),
    };
  },
};
</script>

<style scoped></style>
