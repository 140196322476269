import Vote from "@/lib/models/vote";

class VoteRepo {
  constructor(state) {
    this.state = state;
    this.votes = {};
  }

  SyncGetVote(voteId) {
    return this.votes[voteId];
  }

  async GetVote(voteId) {
    if (this.votes[voteId] !== undefined) {
      return this.votes[voteId];
    }

    let vote = await this.state.contract.contractObj.getVote(voteId);
    this.votes[voteId] = new Vote(voteId, vote);
    return this.votes[voteId];
  }
}

export default VoteRepo;
