import Story from "@/lib/models/story";
import { ethers } from "ethers";

class ListedStory extends Story {
  constructor(story, amount, txHash, createdAtBN) {
    super(story.id, story);
    this.amount = amount;
    this.txHash = txHash;
    this.createdAtBN = createdAtBN;
  }

  get StringAmountFormatted() {
    return ethers.utils.formatEther(this.amount) + " AVAX";
  }

  get TxHash() {
    return this.txHash;
  }

  get StringCreatedAt() {
    return this.createdAtBN;
  }
}

export default ListedStory;
