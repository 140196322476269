<script>
import { h, onMounted, onUpdated, ref } from "vue";

import MarkdownIt from "markdown-it";
import MarkdownItAbbr from "markdown-it-abbr";
import MarkdownItAnchor from "markdown-it-anchor";
import MarkdownItEmoji from "markdown-it-emoji";
import MarkdownItFootnote from "markdown-it-footnote";
import MarkdownItIns from "markdown-it-ins";
import MarkdownItMark from "markdown-it-mark";
import MarkdownItSub from "markdown-it-sub";
import MarkdownItSup from "markdown-it-sup";

const props = {
  source: {
    type: String,
    default: "",
  },
};

export default {
  name: "MarkdownViewer",
  props,
  setup(props) {
    const md = ref();
    const renderMarkdown = () => {
      let markdown = new MarkdownIt()
        .use(MarkdownItAbbr)
        .use(MarkdownItAnchor, {})
        .use(MarkdownItEmoji, {})
        .use(MarkdownItFootnote)
        .use(MarkdownItIns)
        .use(MarkdownItMark)
        .use(MarkdownItSub)
        .use(MarkdownItSup)
        .set({
          breaks: false,
          html: false,
          langPrefix: false,
          linkify: false,
          quotes: false,
          typographer: false,
          xhtmlOut: false,
        });

      md.value = markdown.render(props.source);
    };

    onMounted(() => renderMarkdown());
    onUpdated(() => renderMarkdown());

    return () => h("div", { innerHTML: md.value });
  },
};
</script>

<style lang="scss" scoped>
@import "~github-markdown-css/github-markdown.css";
@import "~highlight.js/styles/default.css";
</style>
