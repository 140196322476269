import Blockstory from "../models/blockstory";
import { ethers } from "ethers";

class ProposalBlockstoryManager {
  constructor(state) {
    this.state = state;
    this.blocks = {};
    this.proposals = {};
    this.linkedBlocks = {};
    this.current = undefined;
    this.count = 0;
  }

  Reset() {
    this.blocks = {};
    this.proposals = {};
    this.linkedBlocks = {};
    this.current = undefined;
    this.count = 0;
  }

  async SetProposalAsActiveBlock(parentId, propId) {
    const linkage = [];
    const parentStory = await this.state.storyCacheService.GetStory(parentId);
    const proposalStory = await this.state.proposalCacheService.GetProposal(
      propId
    );

    linkage.push({
      Id: propId,
      Text: proposalStory.text,
      Title: proposalStory.title,
    });

    this.AddBlockStory(
      parentId,
      undefined,
      parentStory.title,
      parentStory.text,
      linkage
    );

    this.AddProposalBlockStory(
      propId,
      parentId,
      proposalStory.title,
      proposalStory.text,
      []
    );

    this.current = this.proposals[propId];
  }

  async SetActiveBlock(parentId, choiceId) {
    const linkage = [];
    const root = await this.state.storyCacheService.GetStory(choiceId);
    for (let i = 0; i < root.linkage.length; i++) {
      let id = ethers.BigNumber.from(root.linkage[i]).toString();
      let choice = await this.state.storyCacheService.GetStory(id);

      linkage.push({
        Id: id,
        Text: choice.text,
        Title: choice.title,
      });
    }

    this.AddBlockStory(choiceId, parentId, root.title, root.text, linkage);
    this.current = this.blocks[choiceId];
  }

  AddBlockStory(id, storyId, title, text, linkage) {
    const blockstory = new Blockstory(id, storyId, title, text, linkage);
    this.blocks[id] = blockstory;
    this.count++;
  }

  AddProposalBlockStory(id, storyId, title, text, linkage) {
    const blockstory = new Blockstory(id, storyId, title, text, linkage);
    this.proposals[id] = blockstory;
  }

  GetCount() {
    return this.count;
  }

  GetLinkedProposalBlocks() {
    const proposalStory = this.current; // current is a proposal
    const linkedBlocks = [];
    linkedBlocks.push(proposalStory);
    const parentStory = this.GetStory(proposalStory.storyId);
    linkedBlocks.push(parentStory);

    return linkedBlocks.reverse();
  }

  GetLinkedBlocks() {
    let iterNode = this.current;
    const linkedBlocks = [];

    while (iterNode !== undefined) {
      linkedBlocks.push(iterNode);
      iterNode = this.GetStory(iterNode.storyId);
      if (iterNode === undefined) {
        break;
      }
    }

    return linkedBlocks.reverse();
  }

  GetStory(id) {
    return this.blocks[id];
  }

  GetProposal(id) {
    return this.proposals[id];
  }
}

export default ProposalBlockstoryManager;
