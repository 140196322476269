<template>
  <div>
    <h3>
      Owned Stories
      <el-button
        ref="spinner"
        :loading="isLoading"
        circle
        icon="el-icon-refresh-right"
        size="mini"
        @click="refreshStories()"
      ></el-button>
    </h3>

    <el-table
      v-loading="isLoading"
      :data="ownedStories"
      height="400"
      style="width: 100%"
    >
      <el-table-column label="" sortable type="expand">
        <template #default="props">
          <p>blockstory #{{ props.row.Id }}</p>
          <el-row>
            <el-col :span="2" class="subDescription subDescriptionTitle">
              Title
            </el-col>
            <el-col :span="22" class="subDescription subDescriptionData">
              {{ props.row.StringFullTitle }}
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="2" class="subDescription subDescriptionTitle">
              Text
            </el-col>
            <el-col :span="22" class="subDescription subDescriptionData">
              {{ props.row.StringFullText }}
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column label="Story" sortable width="120">
        <template #default="scope">
          <div v-if="scope.row.IsReserved">
            <el-button
              size="mini"
              type="primary"
              @click="openReservedMintDialog(scope.row.Id)"
              >Finalize
            </el-button>
          </div>
          <div v-if="!scope.row.IsReserved">
            <router-link
              :to="{ name: 'BlockStory', params: { story: scope.row.id } }"
            >
              <el-button size="small" type="text"
                >{{ scope.row.StringTitle }}
              </el-button>
            </router-link>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Rarity" prop="RarityString" sortable>
      </el-table-column>
      <el-table-column label="Level" prop="CurrentDepthString" sortable />
      <el-table-column
        label="Choices Left"
        min-width="125"
        prop="CurrentLinksString"
        sortable
      />
      <el-table-column label="Type" prop="Type" sortable />
      <el-table-column label="Received Bids" min-width="125" sortable>
        <template #default="scope">
          <el-button size="small" type="text" @click="viewBids(scope.row.id)">
            {{ scope.row.NumberOfBids }} bid(s)
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="Sale Price" min-width="125" sortable>
        <template #default="scope">
          <el-button
            size="small"
            type="text"
            @click="viewSalePrice(scope.row.id)"
          >
            {{ parseFloat(scope.row.SellPriceString) }} AVAX
          </el-button>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="Operations">
        <template #default="scope">
          <el-button
            size="small"
            type="text"
            @click="claimFarm(scope.row.farmerId)"
            >Claim
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <owned-stories-bids-dialog
      v-model:IsOwnedStoriesBidsDialogVisible="isOwnedStoriesBidsDialogVisible"
      :OwnedStoryId="selectedOwnedStoryId"
      @acceptBidAction="this.acceptBid"
    />
    <owned-story-sell-price-dialog
      v-model:IsOwnedStorySellPriceDialogVisible="
        isOwnedStorySellPriceDialogVisible
      "
      :OwnedStoryId="selectedOwnedStoryId"
      @ChangeSellPriceAction="this.changeSellPrice"
    />

    <writer-dialog
      v-model:IsDialogVisible="isReservedMintDialogVisible"
      v-model:IsWriterDisabled="isReservedMintWriterDisabled"
      :DialogTitle="'Finalize Story: ' + reservedStoryId"
      @triggerAction="this.finalizeMintStory"
    ></writer-dialog>
  </div>
</template>

<script>
import WriterDialog from "@/components/WriterDialog";
import OwnedStoriesBidsDialog from "@/components/wallet/ownedStories/OwnedStoriesBidsDialog";
import OwnedStorySellPriceDialog from "@/components/wallet/ownedStories/OwnedStorySellPriceDialog";

export default {
  name: "OwnedStories",
  components: {
    OwnedStorySellPriceDialog,
    OwnedStoriesBidsDialog,
    WriterDialog,
  },
  props: ["ownedStoryId"],

  data() {
    return {
      collpa: true,
      isLoading: false,
      isReservedMintDialogVisible: false,
      reservedStoryId: undefined,
      isReservedMintWriterDisabled: false,
      isOwnedStoriesBidsDialogVisible: false,
      isOwnedStorySellPriceDialogVisible: false,
      selectedOwnedStoryId: undefined,
    };
  },
  computed: {
    ownedStories() {
      if (!this.$store.state.initialized) {
        this.loadingOwnedStories();
        return [];
      }

      if (this.$store.state.walletManager.IsFetchingReceivedBidsForToken) {
        this.loadingOwnedStories();
        return [];
      }

      const arr = [];
      const ownedStories = this.$store.state.walletManager.GetAllOwnedStories();
      for (const ownedStoryId in ownedStories) {
        arr.push(ownedStories[ownedStoryId]);
      }
      this.readyOwnedStories();
      return arr;
    },
  },
  methods: {
    readyOwnedStories() {
      this.isLoading = false;
    },
    loadingOwnedStories() {
      this.isLoading = true;
    },

    async claimFarm(farmerid) {
      console.log(
        await this.$store.state.contractService.GetFarmValue(farmerid)
      );
      this.loadingOwnedStories();
      await this.$store.state.contractService.ClaimFarm(farmerid);
      this.readyOwnedStories();
    },
    async refreshStories() {
      this.loadingOwnedStories();
      await this.$store.dispatch("reloadOwnedStories");
      this.readyOwnedStories();
    },

    viewBids(bidId) {
      this.selectedOwnedStoryId = bidId;
      this.isOwnedStoriesBidsDialogVisible = true;
    },
    viewSalePrice(bidId) {
      this.selectedOwnedStoryId = bidId;
      this.isOwnedStorySellPriceDialogVisible = true;
    },
    async acceptBid(bidId) {
      this.loadingOwnedStories();
      try {
        const tx = await this.$store.dispatch("acceptBid", {
          bidId,
        });
        this.isOwnedStoriesBidsDialogVisible = false;
        await this.$store.state.transactionManager.WaitFinished(tx.hash);

        this.selectedOwnedStoryId = undefined;
        await this.refreshStories();
      } catch (e) {
        this.readyOwnedStories();
      }
    },
    openReservedMintDialog(reservedStoryId) {
      this.reservedStoryId = reservedStoryId;
      this.isReservedMintDialogVisible = true;
    },

    async finalizeMintStory(title, text) {
      this.isReservedMintWriterDisabled = true;
      await this.$store.dispatch("finalizeMint", {
        reservationId: this.reservedStoryId,
        finalizedTitle: title,
        finalizedText: text,
      });
      this.isReservedMintWriterDisabled = false;
      this.reservedStoryId = undefined;
      this.isReservedMintDialogVisible = false;
    },

    async changeSellPrice(sellPrice) {
      this.loadingOwnedStories();
      try {
        const tx = await this.$store.dispatch("changeSellPrice", {
          storyId: this.selectedOwnedStoryId,
          sellPrice: sellPrice,
        });
        this.isOwnedStorySellPriceDialogVisible = false;
        await this.$store.state.transactionManager.WaitFinished(tx.hash);

        this.selectedOwnedStoryId = undefined;
        await this.refreshStories();
      } catch (e) {
        console.log("errored");
        this.readyOwnedStories();
      }
    },
  },
  mounted() {
    this.$nextTick(function () {
      if (!isNaN(this.ownedStoryId)) {
        this.viewBids(this.ownedStoryId);
      }
    });
  },
};
</script>

<style scoped></style>
