import Story from "@/lib/models/story";
import AsyncLock from "@/lib/AsyncLock";

class StoryCacheService {
  constructor(state) {
    this.stories = {};
    this.proposals = {};
    this.state = state;
    this.lock = new AsyncLock();
  }

  async ForceGetStory(storyId) {
    const story = await this.state.contract.contractObj.getStory(storyId);
    this.stories[storyId] = new Story(storyId, story);
    return this.stories[storyId];
  }

  async GetStory(storyId) {
    await this.lock.promise;
    this.lock.enable();
    if (this.stories[storyId] === undefined) {
      const story = await this.state.contract.contractObj.getStory(storyId);
      this.stories[storyId] = new Story(storyId, story);
      // TODO review mutex
      this.lock.disable();
      return this.stories[storyId];
    }
    this.lock.disable();
    return this.stories[storyId];
  }

  GetStoryIfAvailable(storyId) {
    return this.stories[storyId];
  }
}

export default StoryCacheService;
