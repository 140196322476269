import ListedStory from "@/lib/models/listedStory";

class StoryListManager {
  constructor() {
    this.stories = [];
    this.choices = [];
  }

  AddStory(story, amount, txHash, createdAtBN) {
    if (story.IsStart) {
      if (!this.storyIsListed(story)) {
        this.stories.push(new ListedStory(story, amount, txHash, createdAtBN));
      }
      return;
    }
    if (!this.choiceIsListed(story)) {
      this.choices.push(new ListedStory(story, amount, txHash, createdAtBN));
    }
  }

  GetStories() {
    return this.stories;
  }

  GetChoices() {
    return this.choices;
  }

  storyIsListed(story) {
    for (const storyElement of this.stories) {
      if (story.Id === storyElement.Id) {
        return true;
      }
    }
    return false;
  }

  choiceIsListed(story) {
    for (const storyElement of this.choices) {
      if (story.Id === storyElement.Id) {
        return true;
      }
    }
    return false;
  }
}

export default StoryListManager;
