<template>
  <el-popover
    ref="writeHelperPopover"
    v-model:visible="popOverVisible"
    :popper-options="{
      roundOffsets: { x: 20, y: 200 },
    }"
    :style="{ top: 100 + 'px' }"
    :width="160"
    placement="right-start"
    popper-class="writerPopover"
  >
    <template #reference>
      <el-input
        :disabled="IsWriterDisabled"
        :model-value="InputWriterText"
        placeholder="Once upon a time.."
        rows="20"
        type="textarea"
        @focus="onFocus"
        v-on:keyup="autocompletePopup"
        @update:modelValue="this.$emit('update:InputWriterText', $event)"
      />
    </template>

    <format-helper-popout
      :AvailableIcons="availableIcons"
      :InsertIconAction="insertIcon"
    />
  </el-popover>
</template>

<script>
import IconList from "markdown-it-emoji/lib/data/full.json";
import FormatHelperPopout from "@/components/writer/FormatHelperPopout";

export default {
  name: "InputWriter",
  components: { FormatHelperPopout },
  props: ["InputWriterText", "IsWriterDisabled", "FocusRef"],
  emits: ["update:InputWriterText"],
  data() {
    return {
      selectPosition: {},
      availableIcons: [],
      popOverVisible: false,
    };
  },
  methods: {
    insertIcon(icon) {
      const currentWord = this.findWord(
        this.InputWriterText,
        this.selectPosition
      );
      if (currentWord[currentWord.length - 1] === ":") {
        return;
      }
      this.$emit(
        "update:InputWriterText",
        this.InputWriterText.slice(
          0,
          this.selectPosition - currentWord.length
        ) +
          this.InputWriterText.slice(
            this.selectPosition - currentWord.length
          ).replace(currentWord, icon)
      );
      this.popOverVisible = false;
    },
    autocompletePopup(inputEvent) {
      if (inputEvent === undefined) {
        return undefined;
      }

      this.selectPosition = inputEvent.target.selectionStart;
      const currentWord = this.findWord(
        this.InputWriterText,
        this.selectPosition
      );
      if (currentWord[0] !== ":") {
        this.popOverVisible = false;
        return undefined;
      }

      if (currentWord[currentWord.length - 1] === ":") {
        this.popOverVisible = false;
        return undefined;
      }

      const searchWord = currentWord.substring(1);
      this.popOverVisible = true;

      this.availableIcons = Object.keys(IconList)
        .filter((key) => {
          return key.indexOf(searchWord) === 0;
        })
        .reduce((obj, key) => {
          obj[key] = IconList[key];
          return obj;
        }, {});
    },
    findWord(text, caretPos) {
      const preText = text.substring(0, caretPos);
      let existSpaces = false;
      let existNewlines = false;
      if (preText.indexOf(" ") > 0) {
        existSpaces = true;
      }
      if (preText.indexOf("\n") > 0) {
        existNewlines = true;
      }

      if (existSpaces && existNewlines) {
        const words = preText.split(/\n| /);
        return words[words.length - 1]; //return last word
      }

      if (existSpaces) {
        const words = preText.split(" ");
        return words[words.length - 1]; //return last word
      }

      if (existNewlines) {
        const words = preText.split("\n");
        return words[words.length - 1]; //return last word
      }

      return preText;
    },
    onFocus() {
      this.$nextTick(() => {
        this.FocusRef.scrollIntoView({ behavior: "smooth" });
      });
    },
  },
};
</script>

<style scoped></style>
