<template>
  <div class="banner-header bg-gradient">
    <el-row>
      <el-col :span="4"></el-col>
      <el-col :span="16">
        <balance />
      </el-col>
      <el-col :span="4"></el-col>
    </el-row>
  </div>
</template>

<script>
import Balance from "@/components/wallet/Balance";

export default {
  name: "WalletTitleDivider",
  components: { Balance },
  props: {},
  computed: {},
};
</script>

<style scoped>
.banner-header {
  padding-top: 5vh;
  padding-bottom: 5vh;
}
</style>
