class CurrentTransaction {
  constructor(name) {
    this.tx = undefined;
    this.visible = true;
    this.error = undefined;
    this.loading = true;
    this.finished = undefined;
    this.name = name;
  }

  get Name() {
    return this.name;
  }

  get Visible() {
    return this.visible;
  }

  get Loading() {
    return this.loading;
  }

  get Tx() {
    return this.tx;
  }

  get Error() {
    return this.error;
  }

  get TxId() {
    return this.tx.hash;
  }

  get HasTxId() {
    return this.tx !== undefined && this.tx.hash !== undefined;
  }

  get HasErrored() {
    return this.error !== undefined;
  }

  get HasSucceeded() {
    return this.finished !== undefined;
  }

  SetError(error) {
    this.error = error;
    this.loading = false;
  }

  UpdateTx(tx) {
    this.tx = tx;
    this.loading = false;
  }
}

export default CurrentTransaction;
