<template>
  <h3>
    Sent Bids
    <el-button
      :loading="isLoading"
      circle
      icon="el-icon-refresh-right"
      size="mini"
      @click="refreshSentTokenBids()"
    ></el-button>
  </h3>
  <el-table
    v-loading="isLoading"
    :data="sentStoryBids"
    :default-sort="{ prop: 'Id', order: 'descending' }"
    height="400"
    style="width: 100%"
  >
    <el-table-column type="expand">
      <template #default="props">
        <p>Existing bids on blockstory #{{ props.row.StoryId }}</p>
        <sent-bids-list :StoryId="props.row.StoryId"></sent-bids-list>
      </template>
    </el-table-column>
    <el-table-column label="Story" min-width="150" sortable>
      <template #default="scope">
        <router-link
          :to="{ name: 'BlockStory', params: { story: scope.row.id } }"
        >
          <el-button size="small" type="text"
            >{{ scope.row.ParentStoryTitle }}
          </el-button>
        </router-link>
      </template>
    </el-table-column>
    <el-table-column
      fixed="right"
      label="Bid (AVAX)"
      prop="StringBidAmount"
      sortable
      width="150"
    />
    <el-table-column fixed="right" label="Operations" width="120">
      <template #default="scope">
        <el-button size="small" type="text" @click="cancelBid(scope.row.id)"
          >Cancel
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import SentBidsList from "@/components/wallet/sublists/SentBidsList";

export default {
  name: "SentTokenBids",
  components: { SentBidsList },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    sentStoryBids() {
      if (!this.$store.state.initialized) {
        this.loadingSentTokenBids();
        return [];
      }

      if (this.$store.state.walletManager.IsFetchingSentTokenBinds) {
        this.loadingSentTokenBids();
        return [];
      }

      this.readySentTokenBids();
      return this.$store.state.walletManager.GetSentBidsForToken();
    },
  },
  methods: {
    readySentTokenBids() {
      this.isLoading = false;
    },
    loadingSentTokenBids() {
      this.isLoading = true;
    },
    async refreshSentTokenBids() {
      this.isLoading = true;
      await this.$store.dispatch("reloadSentTokenBids");
      this.isLoading = false;
    },
    async cancelBid(bidId) {
      this.isLoading = true;
      await this.$store.dispatch("cancelBid", { bidId });
      this.isLoading = false;
      await this.refreshSentTokenBids();
    },
  },
};
</script>

<style scoped></style>
