<template>
  <el-dialog
    :model-value="showTxDialog.Visible"
    width="30%"
    @update:modelValue="closeTxDialog($event)"
  >
    <el-card shadow="never">
      <template #header>
        <b>{{ showTxDialog.Name }}</b>
      </template>

      <div v-loading="showTxDialog.Loading">
        <span v-if="showTxDialog.HasErrored">
          <span icon="el-icon-close">ERROR ICON</span>
          <p>Transaction failed</p>
          Code: {{ showTxDialog.Error.code }}

          <p></p>
          <span v-if="showTxDialog.HasTxId">
            View transaction in explorer: {{ showTxDialog.TxId }}
            <p></p>
            <el-button icon="el-icon-close" type="text">Explorer</el-button>
          </span>
        </span>
        <span v-if="showTxDialog.HasTxId">
          <p>Transaction Issued</p>
          Transaction hash: {{ showTxDialog.TxId }}
          <el-button icon="el-icon-close" type="text">Explorer</el-button>
        </span>
      </div>
    </el-card>
  </el-dialog>
</template>

<script>
export default {
  name: "TransactionExecution",
  computed: {
    showTxDialog() {
      if (!this.$store.state.initialized) {
        return { Visible: false };
      }
      const txAction =
        this.$store.state.transactionService.GetTransactionAction();
      if (txAction === undefined) {
        return { Visible: false };
      }
      return txAction;
    },
  },
  methods: {
    closeTxDialog() {
      this.$store.state.transactionService.CloseVisibility();
    },
  },
};
</script>

<style scoped></style>
