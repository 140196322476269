<template>
  <div>
    <p class="header">Format Helper</p>
    <div v-if="showIcons()" style="text-align: right; margin: 0">
      <span v-for="icon in AvailableIcons" :key="icon">
        <el-button size="mini" v-on:click="InsertIconAction(icon)">
          {{ icon }}
        </el-button>
      </span>
    </div>
    <div v-if="showIntro()">Try typing :fa-...</div>
  </div>
</template>

<script>
export default {
  name: "FormatHelperPopout",
  props: ["AvailableIcons", "InsertIconAction"],
  computed: {},
  methods: {
    showIcons() {
      return true;
    },
    showIntro() {
      return !this.showIcons();
    },
  },
};
</script>

<style scoped>
.header {
  font-size: 18px;
  text-align: center;
}
</style>
