import Blockstory from "../models/blockstory";
import { ethers } from "ethers";

class BlockstoryManager {
  constructor(state) {
    this.state = state;
    this.blocks = {};
    this.current = undefined;
  }

  Reset() {
    this.blocks = {};
    this.current = undefined;
  }

  HideProposals(storyId) {
    this.blocks[storyId].ClearProposalLinkages();
  }

  async ShowProposals(storyId) {
    const blockstory = this.GetStory(storyId);
    if (blockstory.IsShowingProposals) {
      return;
    }
    const proposals = await this.state.proposalService.GetProposalsForStory(
      blockstory.id.toString()
    );

    for (const proposal of proposals) {
      blockstory.AddProposalLinkage(proposal);
    }
    this.blocks[blockstory.id] = blockstory;
  }

  async SetProposalAsActiveBlock(parentId, choiceId) {
    const linkage = [];
    const root = await this.state.proposalService.GetProposal(choiceId);

    this.AddBlockStory(
      "p" + choiceId,
      parentId,
      root.title,
      root.text,
      "Proposal",
      linkage
    );
    this.current = this.blocks["p" + choiceId];
  }

  async SetActiveBlock(parentId, choiceId) {
    const linkage = [];
    const root = await this.state.storyCacheService.GetStory(choiceId);
    for (let i = 0; i < root.linkage.length; i++) {
      let id = ethers.BigNumber.from(root.linkage[i]).toString();
      let choice = await this.state.storyCacheService.GetStory(id);

      linkage.push({
        Id: id,
        Text: choice.text,
        Title: choice.title,
        Type: "Story",
      });
    }

    this.AddBlockStory(
      choiceId,
      parentId,
      root.title,
      root.text,
      "Story",
      linkage
    );
    this.current = this.blocks[choiceId];
  }

  AddBlockStory(id, storyId, title, text, type, linkage) {
    const blockstory = new Blockstory(id, storyId, title, text, type, linkage);
    this.blocks[id] = blockstory;
  }

  GetLinkedProposalBlocks() {
    const proposalStory = this.current; // current is a proposal
    const linkedBlocks = [];
    linkedBlocks.push(proposalStory);
    const parentStory = this.GetStory(proposalStory.storyId);
    linkedBlocks.push(parentStory);

    return linkedBlocks.reverse();
  }

  GetLinkedBlocks() {
    let iterNode = this.current;
    const linkedBlocks = [];

    while (iterNode !== undefined) {
      linkedBlocks.push(iterNode);
      iterNode = this.GetStory(iterNode.storyId);
      if (iterNode === undefined) {
        break;
      }
    }

    return linkedBlocks.reverse();
  }

  GetStory(id) {
    return this.blocks[id];
  }

  GetProposal(id) {
    return this.blocks[id];
  }
}

export default BlockstoryManager;
