<template>
  <div>
    <mint-title-divider />
    <el-row>
      <el-col :span="4"></el-col>
      <el-col :span="16">
        <p></p>
        <el-card>
          <p>
            If you're not sure on how the start of your story, you can always
            reserve and finalize it after.
          </p>
          (Stories are only written once...!)
          <p></p>
          <el-button @click="isMintDialogVisible = true">Mint</el-button>
          <el-button @click="reserveMint">Reserve</el-button>
        </el-card>
        <writer-dialog
          v-model:IsDialogVisible="isMintDialogVisible"
          v-model:IsWriterDisabled="isMintWriterDisabled"
          DialogTitle="Mint a new Story"
          @triggerAction="this.mintStory"
        ></writer-dialog>
      </el-col>
      <el-col :span="4"></el-col>
    </el-row>
  </div>
</template>

<script>
import WriterDialog from "@/components/WriterDialog";
import MintTitleDivider from "@/components/mint/MintTitleDivider";

export default {
  name: "Mint",
  components: { MintTitleDivider, WriterDialog },
  data() {
    return {
      isMintDialogVisible: false,
      isMintWriterDisabled: false,
    };
  },
  computed: {},
  methods: {
    async mintStory(title, text) {
      this.isMintWriterDisabled = true;
      await this.$store.dispatch("mintStory", {
        mintTitle: title,
        mintText: text,
      });

      this.isMintWriterDisabled = false;
      this.isMintDialogVisible = false;
    },
    async reserveMint() {
      await this.$store.dispatch("reserveStory");
    },
  },
  async mounted() {
    await this.$store.dispatch("initializeEthers");
    await this.$store.dispatch("reloadMintPrices");
  },
};
</script>

<style scoped></style>
