<template>
  <div class="banner-header bg-gradient">
    <el-row class="bg-gradient">
      <el-col :span="4"></el-col>
      <el-col :span="16">
        <el-row>
          <el-col :span="24">
            <p class="banner-title" style="margin-bottom: 0px; margin-top: 0px">
              <span class="white-text">explore the world of</span>
              <span class="color-green"> blockstory</span>
            </p>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="4"></el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ExplorerTitleDivider",

  props: {},
  computed: {},
};
</script>

<style scoped>
.banner-header {
  padding-top: 5vh;
  padding-bottom: 5vh;
}
</style>
