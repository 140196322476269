<template>
  <h3>
    Sent Proposals
    <el-button
      :loading="isLoading"
      circle
      icon="el-icon-refresh-right"
      size="mini"
      @click="refreshSentProposals()"
    ></el-button>
  </h3>

  <el-table
    v-loading="isLoading"
    :data="sentProposals"
    height="400"
    style="width: 100%"
  >
    <el-table-column label="" sortable type="expand">
      <template #default="props">
        <p>Existing proposals on blockstory #{{ props.row.StoryId }}</p>
        <sent-proposals-list
          :StoryId="props.row.ParentStoryId"
        ></sent-proposals-list>
      </template>
    </el-table-column>
    <el-table-column
      label="Parent Story"
      min-width="150"
      prop="StringParentStoryTitle"
      sortable
    >
      <template #default="scope">
        <router-link
          :to="{
            name: 'BlockStory',
            params: { story: scope.row.ParentStoryId },
          }"
        >
          <el-button size="small" type="text"
            >{{ scope.row.StringParentStoryTitle }}
          </el-button>
        </router-link>
      </template>
    </el-table-column>
    <el-table-column label="Proposal" min-width="150" sortable>
      <template #default="scope">
        <el-button
          size="small"
          type="text"
          @click="showProposalView(scope.row.ParentStoryId, scope.row.id)"
          >{{ scope.row.StringProposalTitle }}
        </el-button>
      </template>
    </el-table-column>
    <el-table-column
      fixed="right"
      label="Bid (AVAX)"
      prop="StringBidAmount"
      sortable
      width="150"
    >
    </el-table-column>
    <el-table-column fixed="right" label="Operations" width="120">
      <template #default="scope">
        <el-button
          size="small"
          type="text"
          @click="cancelProposal(scope.row.id)"
          >Cancel
        </el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-dialog v-model="dialogVisible" width="70%">
    <template #title> Preview Proposal #{{ proposalId }}</template>
    <proposal-story-reader :ProposalId="proposalId"></proposal-story-reader>
  </el-dialog>
</template>

<script>
import ProposalStoryReader from "@/components/ProposalStoryReader";
import SentProposalsList from "@/components/wallet/sublists/SentProposalsList";

export default {
  name: "SentProposals",
  components: { SentProposalsList, ProposalStoryReader },
  data() {
    return {
      isLoading: false,
      dialogVisible: false,
      proposalId: undefined,
      proposalStoryId: undefined,
    };
  },
  computed: {
    sentProposals() {
      if (!this.$store.state.initialized) {
        this.loadingSentProposals();
        return [];
      }

      if (this.$store.state.walletManager.IsFetchingSentProposals) {
        this.loadingSentProposals();
        return [];
      }

      const arr = [];

      const proposals = this.$store.state.walletManager.GetSentProposals();
      for (const prop in proposals) {
        arr.push(proposals[prop]);
      }

      this.readySentProposals();
      return arr;
    },
  },
  methods: {
    readySentProposals() {
      this.isLoading = false;
    },
    loadingSentProposals() {
      this.isLoading = true;
    },
    async showProposalView(parentId, propId) {
      this.$store.state.proposalBlockStoryManager.Reset();
      await this.$store.state.proposalBlockStoryManager.SetProposalAsActiveBlock(
        parentId,
        propId
      );

      this.proposalId = propId;
      this.dialogVisible = true;
    },
    async refreshSentProposals() {
      this.isLoading = true;
      await this.$store.dispatch("reloadSentProposals");
      this.isLoading = false;
    },
    async cancelProposal(proposalId) {
      this.isLoading = true;
      await this.$store.dispatch("cancelProposal", { proposalId });
      this.isLoading = false;
      await this.refreshSentTokenBids();
    },
  },
};
</script>

<style scoped></style>
