<template>
  <div>
    <wallet-title-divider></wallet-title-divider>
    <p></p>
    <el-row>
      <el-col :span="4"></el-col>
      <el-col :span="16">
        <el-tabs type="border-card">
          <el-tab-pane label="Owned">
            <owned-stories :ownedStoryId="ownedStoryId"></owned-stories>
          </el-tab-pane>
          <el-tab-pane label="Received Proposals">
            <received-proposals></received-proposals>
          </el-tab-pane>
          <el-tab-pane label="Sent Bids">
            <sent-token-bids></sent-token-bids>
          </el-tab-pane>
          <el-tab-pane label="Sent Proposals">
            <sent-proposals></sent-proposals>
          </el-tab-pane>
          <el-tab-pane label="Sent Sponsorships">
            <sent-sponsorships />
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col :span="4"></el-col>
    </el-row>
  </div>
</template>

<script>
import OwnedStories from "@/components/wallet/OwnedStories";
import SentTokenBids from "@/components/wallet/SentTokenBids";
import ReceivedProposals from "@/components/wallet/ReceivedProposals";
import SentProposals from "@/components/wallet/SentProposals";
import SentSponsorships from "@/components/wallet/SentSponsorships";
import WalletTitleDivider from "@/components/wallet/WalletTitleDivider";

export default {
  name: "Wallet",
  components: {
    WalletTitleDivider,
    SentSponsorships,
    SentProposals,
    ReceivedProposals,
    SentTokenBids,
    OwnedStories,
  },
  props: ["ownedStoryId"],
  data() {
    return {
      drawer: {
        open: false,
        title: "Bids",
        storyId: undefined,
      },
    };
  },
  computed: {},
  methods: {
    async acceptBid(bidId) {
      await this.$store.dispatch("acceptBid", { bidId });
    },
    handleDrawerClose() {
      this.drawer.title = "";
      this.drawer.open = false;
      this.drawer.storyId = undefined;
    },
    viewBids(storyId) {
      this.drawer.open = true;
      this.drawer.storyId = storyId;
      this.drawer.title = "Bids for " + storyId;
    },
    async cancelBid(bidId) {
      await this.$store.dispatch("cancelBid", { bidId });
    },
  },

  async mounted() {
    await this.$store.dispatch("initializeEthers");
    await this.$store.dispatch("reloadWalletBalance");
    await this.$store.dispatch("reloadOwnedStories");
    await this.$store.dispatch("reloadReceivedProposals");
    await this.$store.dispatch("reloadSentProposals");
    await this.$store.dispatch("reloadSentTokenBids");
    await this.$store.dispatch("reloadSentVotes");
  },
};
</script>

<style>
.subDescription {
  font-weight: 700;
  padding: 6px 10px;
  border: 1px solid #fafaff;
  font-size: 12px;
}

.subDescriptionTitle {
  color: darkgray;
  background: #fafafa;
}

.subDescriptionData {
  word-wrap: break-word;
}
</style>
