<template>
  <div
    style="
      text-align: justify;
      text-justify: inter-word;
      font-family: Roboto, Georgia, Cambria, 'Times New Roman', Times, serif;
      font-size: 21px;
    "
  >
    <p></p>
    <span style="white-space: break-spaces">
      <MarkdownViewer :source="activeText" />
    </span>
    <p></p>
    <h6 v-for="choice in choices" :key="choice.Id">
      <el-button
        v-if="choice.Type === 'Story'"
        plain
        type="primary"
        v-on:click="updateBlockStory(choice.Id, choice.Type)"
        >{{ choice.Title }}
      </el-button>

      <el-button
        v-if="choice.Type === 'Proposal'"
        plain
        type="warning"
        v-on:click="updateBlockStory(choice.Id, choice.Type)"
        >{{ choice.Title }}
      </el-button>
    </h6>

    <el-row>
      <el-col :span="20"></el-col>
      <el-col :span="4" fixed="right">
        <div v-if="OperationButtonVisible === 'true'">
          <el-row>
            <el-col :span="10">
              <el-button v-on:click="toggleBuy()">Buy</el-button>
            </el-col>
            <el-col :span="1" />
            <el-col :span="10">
              <el-button :disabled="canPropose" v-on:click="toggleProposer()"
                >Propose
              </el-button>
            </el-col>
            <el-col :span="3"></el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="20" />
      <el-col :span="4">
        <el-switch
          v-model="value"
          active-color="#13ce66"
          active-text="Show proposals"
          inactive-color="#ff4949"
          inactive-text="Hide proposals"
          v-on:change="toggleShowProposal"
        />
      </el-col>
    </el-row>

    <transition
      name="expand"
      @enter="enter"
      @leave="leave"
      @after-enter="afterEnter"
    >
      <div v-if="buyWindowVisible">
        <buy-story :BlockStoryId="blockStoryId"></buy-story>
      </div>
    </transition>

    <transition
      name="expand"
      @enter="enter"
      @leave="leave"
      @after-enter="afterEnter"
    >
      <div v-if="proposeWindowVisible">
        <story-proposal-action
          :BlockStoryId="blockStoryId"
        ></story-proposal-action>
      </div>
    </transition>
  </div>
</template>

<script>
import BuyStory from "@/components/reader/BuyStory";
import StoryProposalAction from "@/components/reader/StoryProposalAction";
import MarkdownViewer from "@/components/reader/MarkdownViewer";

export default {
  components: { MarkdownViewer, StoryProposalAction, BuyStory },
  props: ["blockStoryId", "updateScroll", "OperationButtonVisible"],
  name: "Story",
  data() {
    return {
      proposeWindowVisible: false,
      buyWindowVisible: false,
      value: false,
    };
  },
  computed: {
    choices() {
      if (!this.$store.state.initialized) {
        return null;
      }
      return this.$store.state.blockStoryManager.GetStory(this.blockStoryId)
        .Linkage;
    },
    activeText() {
      if (!this.$store.state.initialized) {
        return null;
      }
      return this.$store.state.blockStoryManager.GetStory(this.blockStoryId)
        .Text;
    },
    canPropose() {
      if (!this.$store.state.initialized) {
        return false;
      }

      return (
        this.$store.state.storyCacheService.GetStoryIfAvailable(
          this.blockStoryId
        ).MaxLinks === 0
      );
    },
  },
  methods: {
    async toggleShowProposal($event) {
      if ($event) {
        await this.$store.state.blockStoryManager.ShowProposals(
          this.blockStoryId
        );
      } else {
        this.$store.state.blockStoryManager.HideProposals(this.blockStoryId);
      }
    },

    async updateBlockStory(choiceId, type) {
      await this.$store.dispatch("updateBlockStory", {
        storyId: this.blockStoryId,
        choiceId,
        choiceType: type,
      });
      this.updateScroll();
    },

    toggleProposer() {
      this.buyWindowVisible = false;
      this.proposeWindowVisible = !this.proposeWindowVisible;
    },
    toggleBuy() {
      this.proposeWindowVisible = false;
      this.buyWindowVisible = !this.buyWindowVisible;
    },
    enter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = "absolute";
      element.style.visibility = "hidden";
      element.style.height = "auto";

      const height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      // Trigger the animation.
      // We use `requestAnimationFrame` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
    leave(element) {
      const height = getComputedStyle(element).height;

      element.style.height = height;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    },
  },
};
</script>

<style scoped>
.expand-enter-active,
.expand-leave-active {
  transition: height 1s ease-in-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}

* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>
