<template>
  <div>
    <el-card shadow="never">
      <el-table
        v-loading="isLoading"
        :data="sponsors"
        size="mini"
        style="overflow: scroll"
      >
        <el-table-column label="Vote" prop="Id" sortable />
        <el-table-column
          label="Bid (AVAX)"
          prop="StringVoteAmount"
          sortable
          width="150"
        />
        <el-table-column
          fixed="right"
          label="Address"
          prop="TruncatedOwner"
          width="100%"
        />
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "SponsorBreakdownList",
  props: ["ProposalId"],
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    sponsors() {
      if (!this.$store.state.initialized) {
        this.loading();
        return [];
      }

      this.ready();
      return this.$store.state.proposalService.SyncGetProposalVotes(
        this.ProposalId.substring(2)
      );
    },
  },
  methods: {
    loading() {
      this.isLoading = true;
    },
    ready() {
      this.isLoading = false;
    },
  },
  async mounted() {
    this.loading();
    await this.$store.state.proposalService.GetProposalVotes(
      this.ProposalId.substring(2)
    );
    this.ready();
  },
};
</script>

<style scoped></style>
