import Story from "@/lib/models/story";

class OwnedStory extends Story {
  constructor(story) {
    super(story.id, story);
    this.bids = {};
  }

  get NumberOfBids() {
    return Object.keys(this.bids).length;
  }

  get IsReserved() {
    return this.isReserved;
  }
}

export default OwnedStory;
