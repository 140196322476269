class GraphService {
  constructor(state) {
    this.state = state;
  }

  // GetAddressStories returns the stories of the address
  async GetAddressStories(address) {
    return await this.state.graphRepo.GetAddressStories(address);
  }

  SyncGetAddressStories(address) {
    return this.state.graphRepo.SyncGetAddressStories(address);
  }

  // GetAddressReceivedProposals returns the received proposals for address
  async GetAddressReceivedProposals(address) {
    return await this.state.graphRepo.GetAddressReceivedProposals(address);
  }

  SyncGetAddressReceivedProposals(address) {
    return this.state.graphRepo.SyncGetAddressReceivedProposals(address);
  }

  // GetStoryBids returns the bids of a story
  async GetStoryBids(storyId) {
    return await this.state.graphRepo.GetStoryBids(storyId);
  }

  SyncGetStoryBids(storyId) {
    return this.state.graphRepo.SyncGetStoryBids(storyId);
  }

  // GetStoryProposals returns the proposals of a story
  async GetStoryProposals(storyId) {
    return await this.state.graphRepo.GetStoryProposals(storyId);
  }

  SyncGetStoryProposals(storyId) {
    const props = this.state.graphRepo.SyncGetStoryProposals(storyId);
    return props !== undefined ? props : [];
  }

  // GetProposalVotes returns the votes of a proposal
  async GetProposalVotes(proposalId) {
    console.log("Got to ASYNC");
    return await this.state.graphRepo.GetProposalVotes(proposalId);
  }

  SyncGetProposalVotes(proposalId) {
    const props = this.state.graphRepo.SyncGetProposalVotes(proposalId);
    return props !== undefined ? props : [];
  }

  // GetAddressSentBids returns the bids sent by an address
  async GetAddressSentBids(address) {
    return await this.state.graphRepo.GetAddressSentBids(address);
  }

  SyncGetAddressSentBids(address) {
    return this.state.graphRepo.SyncGetAddressSentBids(address);
  }

  // GetAddressSentProposals returns the proposals sent by an address
  async GetAddressSentProposals(address) {
    return await this.state.graphRepo.GetAddressSentProposals(address);
  }

  SyncGetAddressSentProposals(address) {
    return this.state.graphRepo.SyncGetAddressSentProposals(address);
  }

  // GetLatestStories returns the latest stories
  async GetLatestStories() {
    return await this.state.graphRepo.GetLatestStories();
  }

  SyncGetLatestStories() {
    return this.state.graphRepo.SyncGetLatestStories();
  }

  // GetMostExpensiveStories returns the most expensive stories
  async GetMostExpensiveStories() {
    return await this.state.graphRepo.GetMostExpensiveStories();
  }

  SyncGetMostExpensiveStories() {
    return this.state.graphRepo.SyncGetMostExpensiveStories();
  }

  // GetAddressSentVotes returns the votes of an address
  async GetAddressSentVotes(address) {
    return await this.state.graphRepo.GetAddressSentVotes(address);
  }

  SyncGetAddressSentVotes(address) {
    const votes = this.state.graphRepo.SyncGetAddressSentVotes(address);
    if (votes === undefined) {
      return [];
    }
    return votes;
  }
}

export default GraphService;
