<template>
  <el-row class="bg-purple-light">
    <el-col :span="4"></el-col>
    <el-col :span="16">
      <el-row>
        <el-col :span="24">
          <el-button type="text">
            <a href="https://t.me/blockstoryioOfficial">
              <el-image :src="telegramLogo" />
            </a>
          </el-button>
          <el-button type="text">
            <a href="https://twitter.com/blockstoryio">
              <el-image :src="twitterLogo" />
            </a>
          </el-button>
          <el-button type="text">
            <a href="mailto:mail@blockstory.io">
              <el-image :src="emailLogo" />
            </a>
          </el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <p>All right reserved blockstory.io</p>
        </el-col>
      </el-row>
    </el-col>
    <el-col :span="4">
      <el-image
        :src="poweredByAvalanche"
        fit="contain"
        style="height: 120px"
      ></el-image>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "BotBar",
  data() {
    return {
      poweredByAvalanche: require("@/assets/imgs/powerd-avalanche.png"),
      twitterLogo: require("@/assets/imgs/logos/twitter_logo.png"),
      telegramLogo: require("@/assets/imgs/logos/telegram_logo.png"),
      emailLogo: require("@/assets/imgs/logos/mail_logo.png"),
    };
  },
};
</script>

<style scoped>
.el-descriptions__body {
  background: #e215e2 !important;
}
</style>
