import { ethers } from "ethers";

class Story {
  constructor(storyId, story) {
    this.id = storyId;
    this.maxSize = story.maxSize;
    this.linkage = story.linkage;
    this.rarity = story.rarity;
    this.title = story.title;
    this.text = story.text;
    this.maxLinks = story.maxLinks;
    this.depth = story.depth;
    this.isStart = story.isStart;
    this.sellPrice = story.sellPrice;
    this.isReserved = story.isReserved;
    this.parentId = story.parentId;
    this.farmerId = story.farmerId;

    let [rarName, mxLinks, mxDepth] = this._rarityName(story.rarity);
    this.rarityName = rarName;
    this.expectedMaxLinks = mxLinks;
    this.expectedMaxDepth = mxDepth;
  }

  get RarityString() {
    return this.rarityName;
  }

  get IsStart() {
    return this.isStart;
  }

  get MaxLinksString() {
    return this.maxLinks.toString();
  }

  get MaxLinks() {
    return this.maxLinks;
  }

  get CurrentLinksString() {
    return this.maxLinks.toString() + "/" + this.expectedMaxLinks.toString();
  }

  get CurrentDepthString() {
    return this.depth.toString() + "/" + this.expectedMaxDepth.toString();
  }

  get Id() {
    return this.id;
  }

  get Title() {
    return this.title;
  }

  get StringFullTitle() {
    if (this.title === "") {
      return "Not Finalized";
    }
    return this.title;
  }

  get StringFullText() {
    if (this.text === "") {
      return "Not Finalized";
    }
    return this.text;
  }

  get StringTitle() {
    if (this.title === "") {
      return "Not Finalized";
    }

    if (this.title.length <= 15) {
      return this.title;
    }

    return this.title.slice(0, 12) + "...";
  }

  get StringText() {
    if (this.text === "") {
      return "Not Finalized";
    }
    if (this.text.length <= 200) {
      return this.text;
    }

    return this.text.slice(0, 197) + "...";
  }

  get Text() {
    return this.text;
  }

  get SellPrice() {
    return this.sellPrice;
  }

  get SellPriceString() {
    return ethers.utils.formatEther(this.SellPrice);
  }

  get Type() {
    if (this.isReserved) {
      return "Reserved";
    }
    return this.isStart ? "Start" : "Choice";
  }

  get ParentId() {
    return this.parentId.toString();
  }

  _rarityName(rarity) {
    if (rarity < 0) return ["-", 2, 128];
    if (rarity >= 6000) return ["Common", 2, 128];
    if (rarity >= 4000) return ["Uncommon", 3, 128];
    if (rarity >= 2000) return ["Unusual", 5, 64];
    if (rarity >= 1000) return ["Rare", 6, 64];
    if (rarity >= 500) return ["Epic", 7, 64];
    if (rarity >= 250) return ["Mythic", 8, 64];
    if (rarity >= 100) return ["Legendary", 9, 64];
    return ["Diamond Hands", 10, 72];
  }
}

export default Story;
