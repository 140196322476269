<template>
  <el-row>
    <el-col :span="10">
      <p class="banner-title" style="margin-bottom: 0px">
        <span class="white-text">current balance</span>
      </p>
    </el-col>
    <el-col :span="14">
      <p class="banner-title">
        <span class="color-green"> {{ currentBalance }} $AVAX</span>
      </p>
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="10" />
    <el-col :span="14">
      <p class="banner-title" style="margin-top: 0px">
        <el-button :loading="isLoading" @click="walletWithdraw">
          Withdraw
        </el-button>
      </p>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "Balance",
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    currentBalance() {
      if (!this.$store.state.initialized) {
        this.loadingBalance();
        return 0;
      }

      if (this.$store.state.walletManager.IsFetchingWalletBalance) {
        this.loadingBalance();
        return 0;
      }
      this.readyBalance();
      return this.$store.state.walletManager.StringBalance;
    },
  },
  methods: {
    readyBalance() {
      this.isLoading = false;
    },

    loadingBalance() {
      this.isLoading = true;
    },

    async refreshWallet() {
      this.loadingBalance();
      await this.$store.dispatch("reloadWalletBalance");
      this.readyBalance();
    },

    async walletWithdraw() {
      await this.$store.dispatch("walletWithdraw");
      await this.refreshWallet();
    },
  },
};
</script>

<style scoped></style>
