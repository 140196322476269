class VoteService {
  constructor(state) {
    this.state = state;
    this.syncGetCurrentAddressSentVotes = false;
  }

  async GetVote(voteId) {
    return await this.state.voteRepo.GetVote(voteId);
  }

  SyncGetVote(voteId) {
    return this.state.voteRepo.SyncGetVote(voteId);
  }

  async GetCurrentAddressSentVotes() {
    this.syncGetCurrentAddressSentVotes = true;
    const votesSent = [];
    const gVotes = await this.state.graphService.GetAddressSentVotes(
      this.state.walletState.CurrentAddress
    );

    for (const gVote of gVotes) {
      let vote = await this.GetVote(gVote.voteId);
      if (vote !== undefined) {
        vote.SetVoteAmount(gVote.amount);
        votesSent.push(vote);
      }
    }
    this.syncGetCurrentAddressSentVotes = false;
    return votesSent;
  }

  SyncGetCurrentAddressSentVotes() {
    const votesSent = [];
    const gVotes = this.state.graphService.SyncGetAddressSentVotes(
      this.state.walletState.CurrentAddress
    );

    for (const gVote of gVotes) {
      let vote = this.SyncGetVote(gVote.voteId);
      if (vote !== undefined) {
        vote.SetVoteAmount(gVote.amount);
        votesSent.push(vote);
      }
    }
    return votesSent;
  }
}

export default VoteService;
