class Blockstory {
  constructor(id, storyId, title, text, type, linkage) {
    this.id = id;
    this.title = title;
    this.text = text;
    this.storyId = storyId;
    this.linkage = linkage;
    this.type = type;
    this.proposalLinkage = [];
    this.proposalsActive = false;
  }

  get Text() {
    return this.text;
  }

  get Type() {
    return this.type;
  }

  get Title() {
    return this.title;
  }

  get Linkage() {
    return this.linkage.concat(this.proposalLinkage);
  }

  get Id() {
    return this.id;
  }

  get StoryId() {
    return this.storyId;
  }

  get IsShowingProposals() {
    return this.proposalsActive;
  }

  AddProposalLinkage(proposal) {
    this.proposalLinkage.push({
      Id: proposal.id,
      Text: proposal.text,
      Title: proposal.title,
      Type: "Proposal",
    });
    this.proposalsActive = true;
  }

  ClearProposalLinkages() {
    this.proposalLinkage = [];
    this.proposalsActive = false;
  }
}

export default Blockstory;
