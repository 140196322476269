<template>
  <el-row class="home-banner regular-text" style="background-color: #f4f4f4">
    <el-col :span="2"></el-col>
    <el-col :span="20">
      <p class="banner-title color-blue" style="text-align: center">
        subscribe to the
        <span class="color-green">latest updates</span>
      </p>
      <el-row>
        <el-col :span="11" :xs="24" style="text-align: left">
          <p>&nbsp;</p>
          <p style="padding-top: 5vh">
            Subscribe to get the latest updates on blockstory.
          </p>
          <p>
            Be in the loop for release date, new updates, previews and much
            more..!
          </p>
        </el-col>
        <el-col :span="2" :xs="0"></el-col>
        <el-col :span="11" :xs="24" style="text-align: left">
          <form
            id="drip-ef-471348521"
            action="https://www.getdrip.com/forms/471348521/submissions"
            data-drip-embedded-form="471348521"
            method="post"
            style="max-width: 30vw"
          >
            <p style="padding-top: 5vh" />
            <div>
              <label for="drip-first-name">Name</label><br />
              <input
                id="drip-first-name"
                name="fields[first_name]"
                type="text"
                value=""
              />
            </div>

            <div>
              <label for="drip-email">Email Address</label><br />
              <input
                id="drip-email"
                name="fields[email]"
                type="email"
                value=""
              />
            </div>

            <div aria-hidden="true" style="display: none">
              <label for="website">Website</label><br />
              <input
                id="website"
                autocomplete="false"
                name="website"
                tabindex="-1"
                type="text"
                value=""
              />
            </div>
            <input
              id="tags_"
              name="tags[]"
              tabindex="-1"
              type="hidden"
              value="blockstory main page"
            />

            <div>
              <el-button
                data-drip-attribute="sign-up-button"
                plain
                @click="submitItem($event)"
              >
                Sign up
              </el-button>
            </div>
          </form>
        </el-col>
      </el-row>
    </el-col>
    <el-col :span="2"></el-col>
  </el-row>
</template>

<script>
export default {
  name: "MailBanner",
  methods: {
    submitItem(evt) {
      evt.preventDefault();
      document.getElementById("drip-ef-471348521").submit();
    },
  },
};
</script>

<style scoped>
#drip-ef-471348521 {
  font: 0.875rem/1.5rem sans-serif;
  width: 100%;
}

#drip-ef-471348521 h3 {
  font-size: 1.125rem;
  margin: 0 0 0.375rem;
}

#drip-ef-471348521 > div {
  margin-bottom: 0.75rem;
}

#drip-ef-471348521 fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

#drip-ef-471348521 legend {
  margin: 0;
  padding: 0;
}

#drip-ef-471348521 input[type="email"],
#drip-ef-471348521 input[type="number"],
#drip-ef-471348521 input[type="tel"],
#drip-ef-471348521 input[type="text"] {
  margin: 0;
  padding: 0.375rem 0.5625rem;
  width: 100%;
}

#drip-ef-471348521 input[type="checkbox"],
#drip-ef-471348521 input[type="radio"] {
  margin: 0.1875rem 0.1875rem 0 0;
  padding: 0;
}

#drip-ef-471348521 input[type="submit"] {
  margin: 0;
  padding: 0.375rem 0.5625rem;
}
</style>
