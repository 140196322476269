<template>
  <div>
    <welcome-banner class="bg-gradient"></welcome-banner>

    <what-is-blockstory></what-is-blockstory>
    <el-image
      :src="dividerRipImage"
      style="transform: rotate(180deg); width: 100%"
    ></el-image>

    <mint-or-reserve-banner></mint-or-reserve-banner>
    <div class="bg-gradient">
      <dividends-banner></dividends-banner>
      <p></p>
      <voting-banner></voting-banner>
    </div>
    <p></p>
    <roadmap-banner />
    <p></p>
    <mail-banner />
  </div>
</template>

<script>
import WelcomeBanner from "@/components/home/WelcomeBanner";
import WhatIsBlockstory from "@/components/home/WhatIsBlockstory";
import DividendsBanner from "@/components/home/DividendsBanner";
import MintOrReserveBanner from "@/components/home/MintOrReserveBanner";
import VotingBanner from "@/components/home/VotingBanner";
import RoadmapBanner from "@/components/home/RoadmapBanner";
import MailBanner from "@/components/home/MailBanner";

export default {
  name: "Home",
  components: {
    MailBanner,
    RoadmapBanner,
    VotingBanner,
    MintOrReserveBanner,
    DividendsBanner,
    WhatIsBlockstory,
    WelcomeBanner,
  },
  data() {
    return {
      dividerRipImage: require("@/assets/imgs/elements/divider-rip-old.png"),
    };
  },
  computed: {},
  methods: {},

  async mounted() {},
};
</script>

<style>
.banner-image {
  height: 60vh;
}

.title-big {
  font-size: 8rem;
  font-weight: bold;
  line-height: 1;
  text-shadow: 0px 1px, 1px 0px, 1px 0px;
}

.title-med {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
}

.banner-title {
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.1;
  margin-bottom: 0px;
  text-align: left;
  text-shadow: 0px 1px, 1px 0px, 1px 0px;
}

.color-blue {
  color: #005b9e;
}

.color-green {
  color: #4adf83;
}

.banner-blue {
  color: #62cdf5;
}

.banner-green {
  color: #4adf83;
}

.banner-blue-2 {
  color: #00aeef;
}

.banner-blue-3 {
  color: #005b9e;
}

.pull-left {
  margin-left: -10vw;
}

.home-banner {
  min-height: 50vh;
}

.bg-light-blue {
  background-color: #f5e7cf;
}

.tooltip-text {
  padding: 0px !important;
  min-height: 0px !important;
  margin: 0px !important;
}

.bg-gradient {
  background: rgb(0, 93, 160);
  background: linear-gradient(
    90deg,
    rgba(0, 93, 160, 1) 0%,
    rgba(0, 173, 238, 1) 100%
  );
}

.regular-text {
  font-size: 1.1rem !important;
}

.white-text {
  color: white;
}

.no-top-margin {
  margin-top: 0px;
}

@media only screen and (max-width: 768px) {
  .title-big {
    font-size: 5rem;
    font-weight: bold;
    line-height: 1;
    text-shadow: 0px 1px, 1px 0px, 1px 0px;
    padding-top: 0px;
  }

  .pull-left {
    margin-left: 0px;
  }

  .banner-image {
    height: 40vh;
  }
}
</style>
