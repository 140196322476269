module.exports = {
  ContractAddress: "0x341BC8D35F3Db1D1Ba73D203904f450a6A24dDa1",
  TheGraphAddress: "https://api.thegraph.com/subgraphs/name/otherview/derpinos",
  NetworkProvider: "fuji",
  ContractABI: [
    { inputs: [], stateMutability: "nonpayable", type: "constructor" },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "approved",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "Approval",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "operator",
          type: "address",
        },
        {
          indexed: false,
          internalType: "bool",
          name: "approved",
          type: "bool",
        },
      ],
      name: "ApprovalForAll",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "_owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "_storyId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_propId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_amount",
          type: "uint256",
        },
        { indexed: false, internalType: "bool", name: "_active", type: "bool" },
        {
          indexed: false,
          internalType: "bool",
          name: "_accepted",
          type: "bool",
        },
      ],
      name: "BidProposalEvent",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "_owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "_storyId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_bidId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_amount",
          type: "uint256",
        },
        { indexed: false, internalType: "bool", name: "_active", type: "bool" },
        { indexed: false, internalType: "bool", name: "_sold", type: "bool" },
      ],
      name: "BidTokenEvent",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_sender",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_amount",
          type: "uint256",
        },
      ],
      name: "LogFallback",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "_from",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "_to",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "_storyId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_rarityId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "bool",
          name: "_isReserved",
          type: "bool",
        },
        {
          indexed: false,
          internalType: "bool",
          name: "_isStart",
          type: "bool",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_amount",
          type: "uint256",
        },
      ],
      name: "OwnedTokenEvent",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "account",
          type: "address",
        },
      ],
      name: "Paused",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "from",
          type: "address",
        },
        { indexed: true, internalType: "address", name: "to", type: "address" },
        {
          indexed: true,
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "Transfer",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "account",
          type: "address",
        },
      ],
      name: "Unpaused",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "_owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "_voteId",
          type: "uint256",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "_proposalId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_amount",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "bool",
          name: "_isActive",
          type: "bool",
        },
      ],
      name: "VoteProposalEvent",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_sender",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_amount",
          type: "uint256",
        },
      ],
      name: "WithdrawEvent",
      type: "event",
    },
    { stateMutability: "payable", type: "fallback" },
    {
      inputs: [{ internalType: "uint256", name: "_bidId", type: "uint256" }],
      name: "acceptBid",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "_storyId", type: "uint256" },
        { internalType: "uint256", name: "_proposalId", type: "uint256" },
      ],
      name: "acceptProposal",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "to", type: "address" },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
      ],
      name: "approve",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "owner", type: "address" }],
      name: "balanceOf",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "_storyId", type: "uint256" },
        { internalType: "string", name: "_title", type: "string" },
        { internalType: "string", name: "_text", type: "string" },
      ],
      name: "bidProposal",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_storyId", type: "uint256" }],
      name: "bidToken",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_bidId", type: "uint256" }],
      name: "cancelBid",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "_proposalId", type: "uint256" },
      ],
      name: "cancelProposal",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_voteId", type: "uint256" }],
      name: "cancelVote",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_farmerId", type: "uint256" }],
      name: "claimFarm",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "_storyId", type: "uint256" },
        { internalType: "string", name: "_title", type: "string" },
        { internalType: "string", name: "_text", type: "string" },
      ],
      name: "finalizeMint",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
      name: "getApproved",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getAvailableMints",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getBalance",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_id", type: "uint256" }],
      name: "getBid",
      outputs: [
        {
          components: [
            { internalType: "address", name: "owner", type: "address" },
            { internalType: "uint256", name: "storyId", type: "uint256" },
            { internalType: "uint256", name: "bidAmount", type: "uint256" },
            { internalType: "bool", name: "isActive", type: "bool" },
          ],
          internalType: "struct BlockStory.Bid",
          name: "",
          type: "tuple",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_id", type: "uint256" }],
      name: "getFarmValue",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_id", type: "uint256" }],
      name: "getProposal",
      outputs: [
        {
          components: [
            { internalType: "address", name: "owner", type: "address" },
            { internalType: "bool", name: "isActive", type: "bool" },
            { internalType: "bool", name: "isAccepted", type: "bool" },
            { internalType: "uint256", name: "storyId", type: "uint256" },
            {
              internalType: "uint256",
              name: "acceptedStoryId",
              type: "uint256",
            },
            { internalType: "uint256", name: "propAmount", type: "uint256" },
            { internalType: "uint256", name: "farmerId", type: "uint256" },
            { internalType: "string", name: "title", type: "string" },
            { internalType: "string", name: "text", type: "string" },
          ],
          internalType: "struct BlockStory.Proposal",
          name: "",
          type: "tuple",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_id", type: "uint256" }],
      name: "getStory",
      outputs: [
        {
          components: [
            { internalType: "address", name: "owner", type: "address" },
            { internalType: "uint256", name: "parentId", type: "uint256" },
            { internalType: "uint8", name: "maxLinks", type: "uint8" },
            { internalType: "uint8", name: "depth", type: "uint8" },
            { internalType: "bool", name: "isStart", type: "bool" },
            { internalType: "bool", name: "isReserved", type: "bool" },
            { internalType: "uint256", name: "sellPrice", type: "uint256" },
            { internalType: "uint256", name: "rarity", type: "uint256" },
            { internalType: "uint256", name: "farmerId", type: "uint256" },
            { internalType: "uint256[]", name: "linkage", type: "uint256[]" },
            { internalType: "string", name: "title", type: "string" },
            { internalType: "string", name: "text", type: "string" },
          ],
          internalType: "struct BlockStory.Story",
          name: "",
          type: "tuple",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_id", type: "uint256" }],
      name: "getVote",
      outputs: [
        {
          components: [
            { internalType: "address", name: "owner", type: "address" },
            { internalType: "uint256", name: "proposalId", type: "uint256" },
            { internalType: "uint256", name: "farmerId", type: "uint256" },
            { internalType: "bool", name: "isActive", type: "bool" },
          ],
          internalType: "struct BlockStory.Vote",
          name: "",
          type: "tuple",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "string", name: "_name", type: "string" },
        { internalType: "string", name: "_symbol", type: "string" },
        { internalType: "uint256", name: "_maxMints", type: "uint256" },
        { internalType: "string", name: "_base", type: "string" },
      ],
      name: "initialize",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "owner", type: "address" },
        { internalType: "address", name: "operator", type: "address" },
      ],
      name: "isApprovedForAll",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "bool", name: "_reserve", type: "bool" },
        { internalType: "string", name: "_title", type: "string" },
        { internalType: "string", name: "_text", type: "string" },
      ],
      name: "mint",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [],
      name: "name",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
      name: "ownerOf",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "pause",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "paused",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "from", type: "address" },
        { internalType: "address", name: "to", type: "address" },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
      ],
      name: "safeTransferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "from", type: "address" },
        { internalType: "address", name: "to", type: "address" },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
        { internalType: "bytes", name: "_data", type: "bytes" },
      ],
      name: "safeTransferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "operator", type: "address" },
        { internalType: "bool", name: "approved", type: "bool" },
      ],
      name: "setApprovalForAll",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "_storyId", type: "uint256" },
        { internalType: "uint256", name: "_sellPrice", type: "uint256" },
      ],
      name: "setSellPrice",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
      name: "supportsInterface",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "symbol",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
      name: "tokenURI",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "from", type: "address" },
        { internalType: "address", name: "to", type: "address" },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
      ],
      name: "transferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "unpause",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "_proposalId", type: "uint256" },
      ],
      name: "voteProposal",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [],
      name: "withdraw",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    { stateMutability: "payable", type: "receive" },
  ],
};
