<template>
  <el-card shadow="never">
    <template #header>
      <div class="card-header">
        <span>Buy this story</span>
      </div>
    </template>
    <div>
      Owner asking price: {{ this.askingPrice }}
      <p></p>
      Bid amount:
      <el-input-number
        v-model="bidAmount"
        :disabled="isInputProposalDisabled"
        :precision="2"
        :step="0.1"
      />
      <div v-if="bidAmount <= 0" class="notify-text-size">
        Not enough to make a bid.
      </div>

      <div style="text-align: right">
        <el-button
          :disabled="isInputProposalDisabled"
          plain
          type="warning"
          v-on:click="issueBid()"
          >Make a bid
        </el-button>
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "BuyStory",
  props: ["BlockStoryId"],
  data() {
    return {
      isInputProposalDisabled: false,
      bidAmount: 1,
      storySellPrice: -1,
    };
  },
  computed: {
    getStory() {
      if (!this.$store.state.initialized) {
        return null;
      }
      return this.$store.state.storyCacheService.GetStoryIfAvailable(
        this.BlockStoryId
      );
    },
    askingPrice() {
      if (this.getStory === null) {
        return "No price set.";
      }
      if (this.getStory.SellPriceString === "0.0") {
        return "No price set.";
      }

      return this.getStory.SellPriceString + "AVAX";
    },
  },
  methods: {
    async issueBid() {
      this.isInputProposalDisabled = true;
      await this.$store.dispatch("issueTokenBid", {
        storyId: this.BlockStoryId,
        bidAmount: this.bidAmount,
      });
      this.isInputProposalDisabled = false;
    },
  },
};
</script>

<style scoped>
.notify-text-size {
  text-align: left;
  text-justify: none;
  font-size: 13px;
  color: red;
}
</style>
