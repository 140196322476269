<template>
  <el-row style="min-height: 100vh">
    <el-col :span="1" :xs="1"></el-col>
    <el-col :span="11" :xs="22">
      <h1
        class="title-big justified-text white-text"
        style="margin-bottom: 5px"
      >
        Choose
        <p style="margin: 0px">your own</p>
        <p class="banner-green" style="margin: 0px">story</p>
      </h1>
      <p style="padding-top: 5vh"></p>
      <p class="subheader-small justified-text white-text">
        Create your own NFT-based story.
      </p>
      <p class="subheader-small justified-text white-text">
        Play immutable, censorship free adventures with countless choices.
      </p>
      <p class="subheader-small justified-text white-text">
        Grow your story with the finest choices and earn dividends!
      </p>
      <p></p>
    </el-col>
    <el-col :span="12" :xs="22">
      <el-image
        :src="sirenImage"
        fit="contain"
        style="
          min-height: 60vh;
          left: -10vw;
          display: inline-block;
          height: 100%;
          vertical-align: middle;
        "
      ></el-image>
    </el-col>
    <el-image :src="dividerRipImage" style="width: 100%"></el-image>
  </el-row>
</template>

<script>
export default {
  name: "WelcomeBanner",
  data() {
    return {
      sirenImage: require("@/assets/imgs/elements/siren.png"),
      dividerRipImage: require("@/assets/imgs/elements/divider-rip.png"),
    };
  },
};
</script>

<style scoped>
.justified-text {
  text-align: justify;
  text-justify: inter-word;
}

.subheader-small {
  margin: 0px;
  font-size: 1.5rem;
}
</style>
