<template>
  <el-dialog
    :before-close="handleClose"
    :model-value="IsOwnedStorySellPriceDialogVisible"
    title="Update Sale Price"
    width="30%"
    @update:modelValue="
      this.$emit('update:IsOwnedStorySellPriceDialogVisible', $event)
    "
  >
    Sale Price
    <el-input-number
      :model-value="currentSalePrice"
      :precision="2"
      :step="0.1"
      @update:modelValue="salePrice = $event"
    />
    <el-button
      plain
      type="warning"
      v-on:click="this.$emit('ChangeSellPriceAction', this.salePrice)"
      >Set Sale Price
    </el-button>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          @click="
            this.$emit('update:IsOwnedStorySellPriceDialogVisible', false)
          "
          >Cancel</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "OwnedStorySellPriceDialog",
  props: ["IsOwnedStorySellPriceDialogVisible", "OwnedStoryId"],
  emits: ["ChangeSellPriceAction", "update:IsOwnedStorySellPriceDialogVisible"],
  data() {
    return {
      salePrice: 0,
    };
  },
  computed: {
    currentSalePrice() {
      if (!this.$store.state.initialized) {
        return 0;
      }
      const story = this.$store.state.storyCacheService.GetStoryIfAvailable(
        this.OwnedStoryId
      );

      if (this.salePrice !== 0) {
        return this.salePrice;
      }
      if (story !== undefined) {
        return parseFloat(story.SellPriceString);
      }
      return 0;
    },
  },
  methods: {
    handleClose() {
      this.salePrice = 0;
      this.$emit("update:IsOwnedStorySellPriceDialogVisible", false);
    },
  },
};
</script>

<style scoped></style>
