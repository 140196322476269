class ExplorerService {
  constructor(state) {
    this.state = state;
    this.initialLoad = false;
  }

  get Loading() {
    return this.initialLoad;
  }

  async UpdateLatestStories() {
    const stories = await this.state.graphService.GetLatestStories();
    for (const latestStory of stories) {
      let story = await this.state.storyCacheService.GetStory(
        latestStory.storyId
      );

      this.state.storyListManager.AddStory(
        story,
        latestStory.amount,
        latestStory.txHash,
        latestStory.createdAtBN
      );
    }
  }

  async UpdateMostExpensiveStories() {
    const stories = await this.state.graphService.GetMostExpensiveStories();
    for (const expStory of stories) {
      let story = await this.state.storyCacheService.GetStory(expStory.storyId);
      this.state.storyExpensiveListManager.AddStory(
        story,
        expStory.amount,
        expStory.txHash,
        expStory.createdAtBN
      );
    }
  }
}

export default ExplorerService;
