<template>
  <div>
    <explorer-title-divider></explorer-title-divider>

    <p></p>
    <el-row>
      <el-col :span="4"></el-col>
      <el-col :span="16">
        <el-card> Latest stories ...!</el-card>
        <latest-stories-list />
      </el-col>
      <el-col :span="4"></el-col>
    </el-row>

    <BlankSpaceDivider />

    <el-row>
      <el-col :span="4"></el-col>
      <el-col :span="16">
        <el-card> Latest choices ...!</el-card>
        <latest-choices-list />
      </el-col>
      <el-col :span="4"></el-col>
    </el-row>

    <BlankSpaceDivider />

    <el-row>
      <el-col :span="4"></el-col>
      <el-col :span="16">
        <el-card> Priciest stories ...!</el-card>
        <price-choices-list></price-choices-list>
      </el-col>
      <el-col :span="4"></el-col>
    </el-row>
  </div>
</template>

<script>
import PriceChoicesList from "@/components/explorer/PriceChoicesList";
import LatestStoriesList from "@/components/explorer/LatestStories";
import LatestChoicesList from "@/components/explorer/LatestChoices";
import BlankSpaceDivider from "@/components/util/BlankSpaceDivider";
import ExplorerTitleDivider from "@/components/explorer/ExplorerTitleDivider";

export default {
  name: "Explorer",
  components: {
    ExplorerTitleDivider,
    BlankSpaceDivider,
    LatestChoicesList,
    LatestStoriesList,
    PriceChoicesList,
  },
  async mounted() {
    await this.$store.dispatch("initializeEthers");
    await this.$store.dispatch("reloadExplorerStories");
  },
};
</script>

<style scoped></style>
