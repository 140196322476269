import { ethers } from "ethers";
import truncateAddress from "@/lib/truncateAddress";

class Vote {
  constructor(id, vote) {
    this.id = id;
    this.owner = vote.owner;
    this.proposalId = vote.proposalId;
    this.farmerId = vote.farmerId;
    this.isActive = vote.isActive;
  }

  get Id() {
    return this.id;
  }

  get OwnerAddress() {
    return this.owner;
  }

  get StringVoteAmount() {
    return ethers.utils.formatEther(this.voteAmount);
  }

  get TruncatedOwner() {
    console.log(this.owner);
    return truncateAddress(this.owner);
  }

  SetVoteAmount(amount) {
    this.voteAmount = amount;
  }
}

export default Vote;
