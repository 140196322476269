<template>
  This is a proposal screen - not a story screen.
  <div
    style="
      text-align: justify;
      text-justify: inter-word;
      font-family: Roboto, Georgia, Cambria, 'Times New Roman', Times, serif;
      font-size: 21px;
      background-color: #5a8570;
    "
  >
    <p></p>
    <span style="white-space: break-spaces">
      <MarkdownViewer :source="activeText" />
    </span>
    <p></p>
    <h6 v-for="choice in choices" :key="choice.Id">
      <el-button
        plain
        type="primary"
        v-on:click="updateBlockStory(choice.Id, choice.Type)"
        >{{ choice.Title }}
      </el-button>
    </h6>

    <el-row>
      <el-col :span="20"></el-col>
      <el-col :span="4" fixed="right">
        <div v-if="OperationButtonVisible === 'true'">
          <el-row>
            <el-col :span="21">
              <el-button v-on:click="toggleSponsor()">Sponsor</el-button>
            </el-col>
            <el-col :span="3"></el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>

    <transition
      name="expand"
      @enter="enter"
      @leave="leave"
      @after-enter="afterEnter"
    >
      <div v-if="sponsorWindowVisible">
        <sponsor-proposal
          :BlockStoryId="blockStoryId.substring(1)"
        ></sponsor-proposal>
      </div>
    </transition>
  </div>
</template>

<script>
import MarkdownViewer from "@/components/reader/MarkdownViewer";
import SponsorProposal from "@/components/reader/SponsorProposal";

export default {
  components: { SponsorProposal, MarkdownViewer },
  props: ["blockStoryId", "updateScroll", "OperationButtonVisible"],
  name: "Proposal",
  data() {
    return {
      sponsorWindowVisible: false,
    };
  },
  computed: {
    choices() {
      if (!this.$store.state.initialized) {
        return null;
      }
      return this.$store.state.blockStoryManager.GetProposal(this.blockStoryId)
        .Linkage;
    },
    activeText() {
      if (!this.$store.state.initialized) {
        return null;
      }
      return this.$store.state.blockStoryManager.GetProposal(this.blockStoryId)
        .Text;
    },
  },
  methods: {
    toggleSponsor() {
      this.sponsorWindowVisible = !this.sponsorWindowVisible;
    },
    enter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = "absolute";
      element.style.visibility = "hidden";
      element.style.height = "auto";

      const height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      // Trigger the animation.
      // We use `requestAnimationFrame` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
    leave(element) {
      const height = getComputedStyle(element).height;

      element.style.height = height;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    },
  },
};
</script>

<style scoped>
.expand-enter-active,
.expand-leave-active {
  transition: height 1s ease-in-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}

* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>
