<template>
  <div class="banner-header bg-gradient">
    <el-row class="bg-gradient">
      <el-col :span="4"></el-col>
      <el-col :span="16">
        <el-row>
          <el-col :span="24">
            <p class="banner-title" style="margin-bottom: 0px; margin-top: 0px">
              <span class="white-text"
                >minted: {{ 10000 - availableMints }}/10000</span
              >
            </p>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <p class="banner-title" style="margin-bottom: 0px">
              <span class="color-green"> mint Cost: {{ mintCost }}</span>
            </p>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="4"></el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "MintTitleDivider",
  props: {},
  computed: {
    mintCost() {
      if (!this.$store.state.initialized) {
        return "";
      }

      return this.$store.state.contractRepo.MintCost;
    },
    availableMints() {
      if (!this.$store.state.initialized) {
        return "";
      }

      return this.$store.state.contractRepo.AvailableMintsString;
    },
  },
};
</script>

<style scoped>
.banner-header {
  padding-top: 5vh;
  padding-bottom: 5vh;
}
</style>
