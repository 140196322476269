import truncateAddress from "@/lib/truncateAddress";

class Transaction {
  constructor(txData, action, status) {
    this.txData = txData;
    this.action = action;
    this.status = status;
    this.hashId = truncateAddress(txData.hash);
  }

  get TxData() {
    return this.txData;
  }

  get Action() {
    return this.action;
  }

  get Status() {
    return this.status;
  }

  get HashId() {
    return this.hashId;
  }
}

export default Transaction;
