import { ethers } from "ethers";
import truncateAddress from "@/lib/truncateAddress";

class Proposal {
  constructor(id, proposal, parentStory) {
    this.id = id;
    this.owner = proposal.owner;
    this.isActive = proposal.isActive;
    this.propAmount = proposal.propAmount;
    this.title = proposal.title;
    this.text = proposal.text;
    this.parentStory = parentStory;
  }

  get Text() {
    return this.text;
  }

  get StringProposalTitle() {
    if (this.title.length <= 15) {
      return this.title;
    }

    return this.title.slice(0, 12) + "...";
  }

  get Title() {
    return this.title;
  }

  get Id() {
    return this.id;
  }

  get StringId() {
    return this.id.substring(2);
  }

  get StringBidAmount() {
    return ethers.utils.formatEther(this.propAmount);
  }

  get ParentStoryId() {
    return this.parentStory.Id;
  }

  get StringParentStoryTitle() {
    return this.parentStory.StringTitle;
  }

  get BidAmount() {
    return this.propAmount;
  }

  get TruncatedOwner() {
    return truncateAddress(this.owner);
  }
}

export default Proposal;
