<template>
  <div style="padding-top: 10px">
    <el-button v-loading="waitingTransactions" @click="dialogVisible = true">
      {{ currentAddress }}
    </el-button>
  </div>
  <el-dialog v-model="dialogVisible" width="30%">
    <span>{{ currentAddress }}</span>

    <span class="dialog-footer">
      <el-table
        :data="transactions"
        height="250"
        size="mini"
        style="width: 100%"
      >
        <el-table-column label="Hash">
          <template #default="scope">
            <el-button
              icon="el-icon-add-location"
              size="small"
              type="text"
              @click="openExplorer(scope.row.txData.hash)"
            >
              {{ scope.row.HashId }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="Action" prop="action" />
        <el-table-column fixed="right" label="Status" prop="status" />
      </el-table>
    </span>
  </el-dialog>
</template>

<script>
import truncateAddress from "@/lib/truncateAddress";

export default {
  name: "WalletStatus",
  data() {
    return {
      dialogVisible: false,
    };
  },
  computed: {
    currentAddress() {
      return truncateAddress(this.$store.state.walletState.CurrentAddress);
    },
    transactions() {
      if (!this.$store.state.initialized) {
        return [];
      }

      return this.$store.state.transactionManager.GetTransactions();
    },
    waitingTransactions() {
      if (!this.$store.state.initialized) {
        return false;
      }
      return this.$store.state.transactionManager.IsWaitingTransactions();
    },
  },
  methods: {
    openExplorer(txHash) {
      window.open("https://testnet.snowtrace.io/search?q=" + txHash);
    },
  },
};
</script>

<style scoped></style>
