import { ethers } from "ethers";
import TransactionManager from "@/lib/managers/transactionManager";
import WalletManager from "@/lib/managers/walletManager";
import ConnectionProvider from "@/lib/connectionProvider";
import StoryListManager from "@/lib/managers/storyListManager";
import Config from "@/lib/config";
import StoryCacheService from "@/lib/services/storyCacheService";
import ContractService from "@/lib/services/contractService";
import ContractRepo from "@/lib/repo/contractRepo";
import ProposalCacheService from "@/lib/services/proposalCacheService";
import TransactionService from "@/lib/services/transactionService";
import ProposalBlockstoryManager from "@/lib/managers/proposalBlockstoryManager";
import ActivePollers from "@/lib/ActivePollers";
import ExplorerService from "@/lib/services/explorerService";
import BidManager from "@/lib/managers/bidManager";
import BidService from "@/lib/services/bidService";
import BidRepo from "@/lib/repo/bidRepo";
import GraphService from "@/lib/services/graphService";
import GraphRepo from "@/lib/repo/graphRepo";
import ProposalService from "@/lib/services/proposalService";
import ProposalRepo from "@/lib/repo/proposalRepo";
import BlockstoryManager from "@/lib/managers/blockstoryManager";
import VoteService from "@/lib/services/voteService";
import VoteRepo from "@/lib/repo/voteRepo";

class InitHandler {
  constructor(state) {
    this.state = state;
  }

  async InitCurrentAddress() {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");

    // Prompt user for account connections
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();
    let currentAddress = await signer.getAddress();
    console.log("Account:", currentAddress);
    this.state.walletState.CurrentAddress = currentAddress;
    this.state.walletState.provider = provider;

    // connect to contract
    let contract = new ethers.Contract(
      this.state.contract.Address,
      this.state.contract.ABI,
      signer
    );

    this.state.contract.contractObj = contract;
  }

  InitLibraries(store) {
    this.state.transactionManager = new TransactionManager();
    this.state.walletManager = new WalletManager(this.state);
    this.state.connectionProvider = new ConnectionProvider();
    this.state.storyListManager = new StoryListManager();
    this.state.storyExpensiveListManager = new StoryListManager();
    this.state.storyCacheService = new StoryCacheService(this.state);
    this.state.contractService = new ContractService(this.state);
    this.state.blockStoryManager = new BlockstoryManager(this.state);
    this.state.proposalCacheService = new ProposalCacheService(this.state);
    this.state.transactionService = new TransactionService(this.state);
    this.state.proposalBlockStoryManager = new ProposalBlockstoryManager(
      this.state
    );
    this.state.activePollers = new ActivePollers(store);
    this.state.contractRepo = new ContractRepo();
    this.state.explorerService = new ExplorerService(this.state);
    this.state.bidManager = new BidManager(this.state);
    this.state.bidService = new BidService(this.state);
    this.state.bidRepo = new BidRepo(this.state);
    this.state.graphService = new GraphService(this.state);
    this.state.graphRepo = new GraphRepo(this.state);
    this.state.proposalService = new ProposalService(this.state);
    this.state.proposalRepo = new ProposalRepo(this.state);
    this.state.voteService = new VoteService(this.state);
    this.state.voteRepo = new VoteRepo(this.state);
  }

  async EnsureCorrectNetwork() {
    const provider = this.state.walletState.provider;
    try {
      await provider.send("wallet_switchEthereumChain", [
        {
          chainId: this.state.connectionProvider.GetChainConfig(
            Config.NetworkProvider
          ).chainId,
        },
      ]);
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await provider.send({
            method: "wallet_addEthereumChain",
            params: this.state.connectionProvider.GetChainConfig(
              Config.NetworkProvider
            ),
          });
        } catch (addError) {
          console.log(addError);
        }
      }
      console.log("switchError");
      console.log(switchError);
    }
  }
}

export default InitHandler;
